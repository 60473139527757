// Libs
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Components
import { Button } from "../../components/Button/Button";
import { DropdownMenuItem } from "../../components/DropdownMenuItem/DropdownMenuItem";
import { Link } from "../../components/Link/Link";

// Features
import { DropdownMenu } from "../../features/DropdownMenu/DropdownMenu";

import styles from "./Header.module.scss";

// Assets
import { ReactComponent as JobsIcon } from "../../assets/icons/jobs-3.svg";
import { ReactComponent as MegaphoneIcon } from "../../assets/icons/megaphone.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/icons/archive.svg";
import { ReactComponent as CVIcon } from "../../assets/icons/cv.svg";
import { ReactComponent as RocketIcon } from "../../assets/icons/rocket.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/question.svg";
import { ReactComponent as ChairIcon } from "../../assets/icons/chair.svg";
import { ReactComponent as BulbIcon } from "../../assets/icons/bulb.svg";
import { ReactComponent as BriefcaseIcon } from "../../assets/icons/star.svg";
import { ReactComponent as StudentIcon } from "../../assets/icons/student.svg";
import { ReactComponent as BlogIcon } from "../../assets/icons/blog-text.svg";
import { ReactComponent as VideoIcon } from "../../assets/icons/video-camera-alt.svg";
import { ReactComponent as DiamondIcon } from "../../assets/icons/diamond.svg";

export const Header: React.FunctionComponent = () => {
  const [hamburgerActive, setHamburgerActive] = useState(false);
  const location = useLocation();

  const shouldShowButton = useMemo(() => {
    return !location.pathname.includes("posao");
  }, [location]);

  const history = useHistory();

  const onLogoClick = () => {
    history.push("/");
    window.location.reload();
  };

  return (
    <div className={classNames(styles.container)}>
      <div className={styles.logo} onClick={onLogoClick}></div>
      <div
        className={classNames(styles.nav, hamburgerActive ? styles.active : "")}
      >
        <Link to="/">Početna</Link>

        <DropdownMenu text="Poslovi">
          <DropdownMenuItem
            text="Svi poslovi"
            color="#F01D26"
            Icon={JobsIcon}
            route="/svi-poslovi/1"
          />
          <DropdownMenuItem
            text="Oglasi iz sredstava informisanja"
            color="#006CB9"
            Icon={MegaphoneIcon}
            route="/oglasi-iz-sredstava-informisanja/1"
          />
          <DropdownMenuItem
            text="Arhiva poslova"
            color="#675da9"
            Icon={ArchiveIcon}
            route="/arhiva/1"
          />
        </DropdownMenu>

        <DropdownMenu text="Dok tražite posao">
          <DropdownMenuItem
            text="Pripremite se za intervju"
            color="#ddd200"
            Icon={CVIcon}
            route="/pripremite-se-za-intervju"
          />
          <DropdownMenuItem
            text="Korisni savjeti"
            color="#F5811D"
            Icon={RocketIcon}
            route="/korisni-savjeti"
          />
          <DropdownMenuItem
            text="Najčešća pitanja"
            color="#00AADC"
            Icon={QuestionIcon}
            route="/pitanja"
          />
        </DropdownMenu>

        <Link to="/obuke">Obuke</Link>

        {/* <Link to="/studentski-servis"> Studentski servis </Link> */}

        <DropdownMenu text="Usluge">
          <DropdownMenuItem
            text="Tražite radnike"
            color="#9BD4ED"
            Icon={ChairIcon}
            route="/trazite-radnike"
          />
          <DropdownMenuItem
            text="Poslovno savjetovanje"
            color="#8EC33B"
            Icon={BulbIcon}
            route="/poslovno-savjetovanje"
          />
          <DropdownMenuItem
            text="Karijerno savjetovanje"
            color="#046844"
            Icon={BriefcaseIcon}
            route="/karijerno-savjetovanje"
          />
          <DropdownMenuItem
            text="Studentski servis"
            color="#14286C"
            Icon={StudentIcon}
            route="/studentski-servis"
          />
        </DropdownMenu>

        <Link to="/blog/1"> Blog </Link>

        <DropdownMenu text="Portfolio">
          <DropdownMenuItem
            text="Mediji"
            color="#FF8B19"
            Icon={BlogIcon}
            route="/mediji/1"
          />
          <DropdownMenuItem
            text="Video materijali"
            color="#db5656"
            Icon={VideoIcon}
            route="/video-materijali"
          />
          <DropdownMenuItem
            text="Projekti"
            color="#5C78FF"
            Icon={DiamondIcon}
            route="/projekti"
          />
        </DropdownMenu>
        {/* <Link to="/mediji/1"> Mediji </Link> */}

        <Link to="/kontakt"> Kontakt </Link>
      </div>
      <div className={styles.action}>
        {shouldShowButton && (
          <Button
            type="primary"
            width="100%"
            className={styles.button}
            onClick={() => history.push("/zakazi-razgovor")}
          >
            Zakažite razgovor
          </Button>
        )}
      </div>

      <div
        className={classNames(
          styles.hamburger,
          hamburgerActive ? styles.active : ""
        )}
        onClick={() => setHamburgerActive(!hamburgerActive)}
      >
        <div className={styles.bar}></div>
        <div className={styles.bar}></div>
        <div className={styles.bar}></div>
      </div>
    </div>
  );
};
