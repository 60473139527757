import { ReactElement } from "react";
import { Route, useLocation } from "react-router-dom";

export interface RouteWrapperProps {
  component: React.FC;
  exact: boolean;
  path: string;
  layout: React.FC<{ children: ReactElement }>;
}

export const RouteWrapper = ({
  component: Component,
  layout: Layout,
  ...rest
}: RouteWrapperProps) => {
  const location = useLocation();

  return (
    <Route
      {...rest}
      key={location.pathname}
      render={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    ></Route>
  );
};
