// Libs
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropzone from "react-dropzone";

// Components
import { FormGroup, TextField } from "@mui/material";
import { Button } from "../Button/Button";
import { ToastMessage } from "../ToastMessage/ToastMessage";
import { File } from "../File/File";

// Assets
import UploadFileIcon from "@mui/icons-material/UploadFile";

import api from "../../api";
import { MAX_FILE_SIZE } from "../../constants";

import styles from "./ApplicationForm.module.scss";

type FormValues = {
  ime: string;
  prezime: string;
  email: string;
  brojTelefona: string;
  komentar: string;
  fajlovi: File[];
};

interface ApplicationFormProps {
  id: string;
}

const schema = yup.object().shape({
  ime: yup.string().required("Polje je obavezno"),
  prezime: yup.string().required("Polje je obavezno"),
  email: yup.string().email("Neispravan format").required("Polje je obavezno"),
  brojTelefona: yup.string().required("Polje je obavezno"),
  // .matches(phoneRegExp, "Uneseni broj nema odgovarajuci format"),
  komentar: yup.string(),
  fajlovi: yup.mixed(),
});

export const ApplicationForm: React.FunctionComponent<ApplicationFormProps> = ({
  id,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isToastVisible, setIsToastVisible] = useState(false);

  const {
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    defaultValues: {
      ime: "",
      prezime: "",
      email: "",
      brojTelefona: "",
      fajlovi: [],
      komentar: "",
    },
  });

  const removeFile = (file: File) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const onSubmit = async ({
    ime,
    prezime,
    email,
    brojTelefona,
    komentar,
    fajlovi,
  }: FormValues) => {
    const formData = new FormData();

    formData.append("ime", ime);
    formData.append("prezime", prezime);
    formData.append("email", email);
    formData.append("brojTelefona", brojTelefona);
    formData.append("komentar", komentar);

    fajlovi.map((file) => {
      formData.append("fajlovi", file);
    });

    try {
      reset();
      setFiles([]);
      await api.konkursi.applyForJob(id, formData);
      setIsToastVisible(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onDrop = (acceptedFiles: any) => {
    let tempFiles = [...files];

    acceptedFiles.forEach((file: File) => {
      tempFiles.push(file);
    });

    let result = tempFiles.length >= 5 ? tempFiles.slice(0, 5) : tempFiles;

    setFiles(result);
    setValue("fajlovi", result);
  };

  return (
    <div className={styles.container}>
      <h1>Prijavite se za posao</h1>

      {isToastVisible && (
        <ToastMessage
          open={isToastVisible}
          message="Vaša prijava za posao je poslata!"
          severity="success"
          onClose={() => setIsToastVisible(false)}
        />
      )}

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className={styles.topInputs}>
          <Controller
            name="ime"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.input}
                id="outlined-required"
                label="Ime *"
                placeholder="Ime"
                margin="none"
                error={!!errors.ime}
                helperText={errors.ime ? errors.ime.message : ""}
              />
            )}
          />

          <Controller
            name="prezime"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.input}
                id="outlined-required"
                label="Prezime *"
                placeholder="Prezime"
                margin="none"
                error={!!errors.prezime}
                helperText={errors.prezime ? errors.prezime.message : ""}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.input}
                type="email"
                id="outlined-required"
                label="Email *"
                placeholder="primjer@mail.com"
                margin="none"
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            )}
          />

          <Controller
            name="brojTelefona"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="tel"
                className={styles.input}
                id="outlined-required"
                label="Broj telefona *"
                placeholder="+387 xx xxx xxx"
                error={!!errors.brojTelefona}
                helperText={
                  errors.brojTelefona ? errors.brojTelefona.message : ""
                }
              />
            )}
          />
        </FormGroup>

        <div className={styles.bottomInputs}>
          <Controller
            name="komentar"
            control={control}
            render={({ field }) => (
              <TextField
                label="Poruka"
                placeholder="Ovdje možete ostaviti svoju poruku"
                multiline
                fullWidth
                {...field}
                rows={4}
                error={!!errors.komentar}
                helperText={errors.komentar ? errors.komentar.message : ""}
              />
            )}
          />

          <Controller
            name="fajlovi"
            control={control}
            render={(props) => (
              <Dropzone
                maxFiles={5}
                maxSize={MAX_FILE_SIZE}
                accept={{
                  "image/jpeg": [],
                  "application/pdf": [],
                  "application/msword": [],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [],
                }}
                onDrop={onDrop}
                {...props}
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    className={styles.upload}
                    style={{
                      borderColor: errors.fajlovi && !files ? "red" : "#00aadc",
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} name="fajlovi" />
                    <p>Prevucite dokument ili kliknite da izaberete. </p>
                    <p>
                      Maksimalno možete unijeti 5 dokumenata veličine 5MB (.pdf,
                      .docx, .doc, .jpg).
                    </p>

                    <UploadFileIcon style={{ color: "#00aadc" }} />
                  </section>
                )}
              </Dropzone>
            )}
          />

          <div>
            {files && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {files.map((f) => (
                  <File file={f} removeFile={removeFile} />
                ))}
              </div>
            )}
          </div>
        </div>

        <Button type="submit" style={{ margin: "auto", width: "200px" }}>
          Prijavi se
        </Button>
      </form>
    </div>
  );
};
