import { Routes } from "../routes/Routes";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "moment/locale/bs";
import moment from "moment";

moment.locale("bs");

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bs">
      <Routes />
    </LocalizationProvider>
  );
}

export default App;
