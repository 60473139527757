// Libs
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Components
import { Pagination } from "@mui/material";
import { LoadingFallback } from "../../components/LoadingFallback/LoadingFallback";
import { View } from "../../containers/view/View";
import { BlogPost } from "./BlogPost/BlogPost";

// Utils
import api from "../../api";
import { Blog } from "../../models/Blog";
import { PageableBlogResponse } from "../../models/PageableBlogResponse";
import styles from "./BlogPage.module.scss";
import { previewImage } from "../../constants";

const PAGE_SIZE = 10;

interface PageParams {
  page: string;
}

export const BlogPage: React.FunctionComponent = () => {
  const { page } = useParams<PageParams>();
  const history = useHistory();

  const [posts, setPosts] = useState<Blog[]>([]);

  const [pagination, setPagination] = useState<PageableBlogResponse>();
  const [loading, setLoading] = useState(false);

  const fetchPosts = useCallback(async (page: number, size: number) => {
    setLoading(true);
    try {
      const response = await api.blogs.fetchBlogPosts(page, size);

      if (response.status === 200) {
        const data = await response.json();
        setPagination(data);
        setPosts(data.content);
        setLoading(false);
      }
    } catch (err) {}
  }, []);

  const handlePageChange = (event: any, page: any) => {
    history.push("/blog/" + page);
  };

  useEffect(() => {
    fetchPosts(+page - 1, PAGE_SIZE);
  }, [page, fetchPosts]);

  return (
    <View
      title="Blog"
      description="Na ovom linku pronađite naše tekstove vezane za tržište rada. U brojnim tekstovima proteklih godina, iznoslil smo svoje stavove, nudili ideje i rješenja za mnoge probleme koje muče aktere tržišta rada."
    >
      <Helmet>
        <title>Blog | Agencija Spektar</title>
        <meta name="description" content="Agencija Spektar" />
        <meta property="og:title" content="Blog | Agencija Spektar" />
        <meta name="image" property="og:image" content={previewImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Agencija Spektar" />
      </Helmet>

      <View.Content>
        <div className={styles.content}>
          {loading ? (
            <LoadingFallback />
          ) : (
            posts.map((post) => <BlogPost blog={post} key={post.id} />)
          )}
        </div>

        <Pagination
          page={+page}
          count={pagination?.totalPages}
          color="primary"
          shape="rounded"
          variant="outlined"
          style={{ paddingBottom: "10vh", margin: "auto" }}
          onChange={handlePageChange}
        />
      </View.Content>
    </View>
  );
};
