import { Helmet } from "react-helmet";
import { previewImage } from "../../constants";

// Containers
import { View } from "../../containers/view/View";

import styles from "./PrivacyPolicy.module.scss";

export const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <View>
      <Helmet>
        <title>Politika privatnosti | Agencija Spektar</title>
        <meta
          property="og:title"
          content="Politika privatnosti | Agencija Spektar"
        />
        <meta property="og:image" content={previewImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Agencija Spektar" />
      </Helmet>

      <View.Content>
        <div className={styles.content}>
          <h1>Politika privatnosti</h1>
          <p>
            Agencija Spektar čiji veb portal ili aplikaciju koristite, poštuje
            pravo na privatnost korisnika naših usluga i garantuje im pravo da
            odaberu da primaju podatke koji se na njih odnose. Lični podaci
            naših korisnika obrađuju se u skladu sa važećim odredbama zakonskih
            propisa Republike Srpske i BiH, kao i u skladu sa važećim odredbama
            direktive Evropske unije. Naša Politika privatnosti omogućava Vam da
            saznate kako obrađujemo lične podatke korisnika naših usluga, u
            svojstvu korisnika portala i aplikacija, a prije svega:
          </p>

          <h2>Ko je administrator vaših podataka?</h2>
          <p>
            Agencija Spektar je isključivi administrator vaših podataka, čiji
            veb portal ili aplikaciju koristite.{" "}
          </p>

          <h2>Kako nas kontaktirati u vezi sa vašim podacima?</h2>
          <p>
            Za obradu vaših podataka i sprovođenje vaših prava vezanih za
            obradu, možete se obratiti putem mejla{" "}
            <a href="mailto:info@agencijaspektar.com">
              info@agencijaspektar.com
            </a>
          </p>

          <h2>Koje podatke obrađujemo?</h2>
          <p>
            Agencija Spektar obrađuje Vaše prijave na objavljene konkurse
            agencije Spektar na portalu{" "}
            <a href="www.agencijaspektar.com" target="_blank">
              www.agencijaspektar.com{" "}
            </a>
            .
          </p>

          <h2> U koju svrhu i po kom osnovu obrađujemo podatke?</h2>
          <p>
            Na osnovu Vašeg dobrovoljnog pristanka za obradu podataka od strane
            Agencije Spektar, Vaše podatke nam ustupate za bilježenje istorije
            prjavljivanja na otvorene konkurse objavljene na našem veb portalu{" "}
            <a href="www.agencijaspektar.com" target="_blank">
              www.agencijaspektar.com{" "}
            </a>
          </p>

          <h2>Koliko dugo procesuiramo podatke?</h2>
          <p>
            Vaši podaci će biti obrađivani sve dok postoji legitimni interes za
            njihovu obradu, do trenutka opoziva saglasnosti za obradu.
          </p>

          <h2>Koja su prava naših korisnika u smislu obrade podataka?</h2>
          <p>
            {" "}
            U skladu sa odredbama Opšte Uredbe o zaštiti ličnih podataka (Uredbe
            Evropskog parlamenta i Savjeta (EU) 2016/679 koja se primjenjuje od
            25.05.2018. godine), korisnik ima sljedeća prava u odnosu na obradu
            podataka sa naše strane:
          </p>
          <ul>
            <li>
              pravo pristupa svojim podacima, uključujući i dobijanje kopije
              podataka,
            </li>
            <li>pravo na zahtjevane ispravke podataka </li>
            <li>pravo na brisanje ličnih podataka, </li>
            <li>
              pravo na podnošenje žalbe nadzornom organu za zaštitu ličnih
              podataka,{" "}
            </li>
            <li>pravo ograničavanja obrade podataka.</li>
          </ul>

          <p>
            Ako se Vaši podaci obrađuju na osnovu saglasnosti, imate pravo da
            opozovete Vašu prethodno datu saglasnost. Opoziv saglasnosti ne
            utiče na zakonitost obrade koja je izvršena na osnovu saglasnosti
            prije Vašeg opoziva.
          </p>
          <p>
            U cilju ostvarivanja Vaših gore navedenih prava, možete nam se sa
            obrazloženim zahtjevom obratiti na:
            <a href="mailto:info@agencijaspektar.com">
              info@agencijaspektar.com
            </a>
          </p>

          <h2>Sa kim dijelimo podatke?</h2>
          <p>
            Naglašavamo da se Vaš podaci nikada ne dijele sa trećim licima bez
            Vaše prethodne saglasnosti i zadržavamo obavezu da Vas obavijestimo
            o tome, ukoliko dođe do promjene naše poslovne politike.
          </p>

          <h2>Unapređenje funkcionalnosti</h2>
          <p>
            Pamćenje Vaših izbora i interesovanja za objavljene otvorene
            konkurse na veb portalu{" "}
            <a href="www.agencijaspektar.com" target="_blank">
              www.agencijaspektar.com{" "}
            </a>{" "}
            , region u kome se nalazite, omogućiće Vam pravovremene informacije
            i relevantne sadržaje naših usluga, prije svega otvorenih konkursa.
          </p>

          <p>Vaša Agencija Spektar</p>
        </div>
      </View.Content>
    </View>
  );
};
