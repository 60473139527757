import { Helmet } from "react-helmet";

// Components
import { View } from "../../containers/view/View";
import { AppointmentForm } from "./AppointmentForm/AppointmentForm";

import styles from "./AppointmentPage.module.scss";
import { previewImage } from "../../constants";

export const AppointmentPage: React.FunctionComponent = () => {
  return (
    <View>
      <Helmet>
        <title>Zakažite sastanak | Agencija Spektar</title>
        <meta name="description" content="Agencija Spektar" />
        <meta
          property="og:title"
          content="Zakažite sastanak | Agencija Spektar"
        />
        <meta property="og:site_name" content="Agencija Spektar" />
        <meta property="og:image" content={previewImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>

      <View.Content>
        <span
          style={{ margin: "30px auto", width: "60%", textAlign: "center" }}
        >
          Popunjavanjem ovog formulara zakažite razgovor u agenciji Spektar. U
          tekstu (poruci) navedite razlog zbog kojeg nas kontaktirate kako bismo
          pripremili odgovarajućeg sagovornika. Potrudićemo se da Vam odgovorimo
          u najkraćem mogućem roku.
        </span>
        <AppointmentForm />
        <div className={styles.note}>
          <h1>Napomena</h1>
          <p>
            Ako tražite posao, popunite formular i dodajte svoju biografiju ili
            druga dokumenta koja se odnose na prethodno radno iskustvo.
          </p>
        </div>
      </View.Content>
    </View>
  );
};
