import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/auth-context";
import { Button } from "../../components/Button/Button";
import logo from "../../assets/logos/logo.png";
import styles from "./AdminLayout.module.scss";

interface AdminLayoutProps {
  children: React.ReactNode;
}

const AdminLayout = ({ children }: AdminLayoutProps) => {
  const history = useHistory();
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const onLogoClick = () => {
    history.push("/");
  };

  const onLogOut = () => {
    fetch(process.env.REACT_APP_BACKEND_URL + "logout", {
      credentials: "include",
    }).then(() => {
      setIsLoggedIn(false);
      history.push("/prijava");
    });
  };

  return (
    <div className={styles.adminContainer}>
      <div className={styles.adminSideMenu}>
        <img
          src={logo}
          alt="logo"
          className={styles.logo}
          onClick={onLogoClick}
        />

        <ul className={styles.navigation}>
          <NavLink
            to="/admin/blogovi"
            className={styles.link}
            activeClassName={styles.active}
          >
            Blogovi
          </NavLink>
          <NavLink
            to="/admin/pravna-lica"
            className={styles.link}
            activeClassName={styles.active}
          >
            Pravna lica
          </NavLink>
          <NavLink
            to="/admin/sponzorisana-pravna-lica"
            className={styles.link}
            activeClassName={styles.active}
          >
            Sponzorisana pravna lica
          </NavLink>
          <NavLink
            to="/admin/konkursi"
            className={styles.link}
            activeClassName={styles.active}
          >
            Konkursi
          </NavLink>
        </ul>

        <Button
          type="default"
          width="80%"
          className={styles.btn}
          onClick={onLogOut}
        >
          Odjavi se
        </Button>
      </div>
      <div className={styles.adminContent}>{children}</div>
    </div>
  );
};

export default AdminLayout;
