import styles from "./File.module.scss";

import doc from "../../assets/files/documents.png";
import docx from "../../assets/files/docx.png";
import jpg from "../../assets/files/jpg.png";
import pdf from "../../assets/files/pdf.png";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

interface FileProps {
  file: File;
  removeFile: (file: File) => void;
}

const fileTypes: Record<string, string> = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    docx,
  "application/msword": doc,
  "application/pdf": pdf,
  "image/jpeg": jpg,
};

export const File: React.FunctionComponent<FileProps> = ({
  file,
  removeFile,
}) => {
  return (
    <div className={styles.file}>
      <CloseIcon className={styles.close} onClick={() => removeFile(file)} />
      <img src={fileTypes[file.type]} alt="" />
      <p>{file.name}</p>
    </div>
  );
};
