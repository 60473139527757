import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

import styles from "./ViberGroup.module.scss";

// Assets
import { ReactComponent as ViberIcon } from "../../assets/icons/viber.svg";

export const ViberGroup: React.FunctionComponent = () => {
  const gaEventTracker = useAnalyticsEventTracker("Social");

  const onViberClick = () => {
    gaEventTracker("Viber Click", "Pridruživanje Viber grupi");
    window.open("https://bit.ly/2QPNCFf", "_blank");
  };

  return (
    <div className={styles.viber} onClick={onViberClick}>
      <ViberIcon className={styles.icon} />
      <p className={styles.text}>Pridružite se Viber zajednici</p>
    </div>
  );
};
