// Libs
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// Components
import { BlogCard } from "../../components/BlogCard/BlogCard";
import { LoadingFallback } from "../../components/LoadingFallback/LoadingFallback";
import { View } from "../../containers/view/View";

// Utils
import { Blog } from "../../models/Blog";
import api from "../../api";
import styles from "./InterviewPage.module.scss";

// Assets
import DownloadIcon from "@mui/icons-material/Download";
import { previewImage } from "../../constants";

export const InterviewPage: React.FunctionComponent = () => {
  const [posts, setPosts] = useState<Blog[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await api.blogs.fetchBlogs("6");
      const data = await response.json();
      if (response.status === 200) {
        setPosts(data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <View
      title="Pripremite se za intervju"
      description="U ovom odeljku ćete naći korisne savete profesionalaca o tome kako da se pripremite za intervju za posao.
      "
    >
      <Helmet>
        <title>Pripremite se za intervju | Agencija Spektar</title>
        <meta name="description" content="Agencija Spektar" />
        <meta
          property="og:title"
          content="Pripremite se za intervju | Agencija Spektar"
        />
        <meta property="og:image" content={previewImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Agencija Spektar" />
      </Helmet>
      <View.Content>
        <div className={styles.cards}>
          {loading && <LoadingFallback />}
          {!loading && posts.length === 0 ? (
            <p style={{ margin: "auto", marginTop: "20vh" }}>
              Nisu pronađene nove objave.
            </p>
          ) : (
            posts.map((e) => {
              return <BlogCard blog={e} key={e.id} />;
            })
          )}
        </div>
        <div className={styles.section}>
          <h1>Obrasci</h1>
          <p>
            CV treba da privuče pažnju i da Vas istakne kao potencijano
            poželjnog saradnika. U prilogu se nalazi jedan od mogućih oblika.
          </p>

          <div className={styles.templates}>
            <a
              href="https://test.agencijaspektar.com:9000/cvobrazac/CvObrazac.docx"
              download
            >
              CV obrazac <DownloadIcon />
            </a>
          </div>
        </div>
      </View.Content>
    </View>
  );
};
