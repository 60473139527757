// Libs
import { useEffect, useState } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";

// Components
import { Divider } from "../../components/Divider/Divider";
import { LogoSlider } from "../../components/LogoSlider/LogoSlider";
import { Footer } from "../../layouts/Footer/Footer";
import { Button } from "../../components/Button/Button";

// Assets
import mail from "../../assets/icons/mail.png";
import phone from "../../assets/icons/phone.png";
import address from "../../assets/icons/address.png";

// Utils
import styles from "./ContactPage.module.scss";
import { previewImage } from "../../constants";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import { Logo } from "../../api/services/pravna-lica";
import api from "../../api";

export const ContactPage: React.FunctionComponent = () => {
  const [logos, setLogos] = useState<Logo[]>([]);

  const gaEventTracker = useAnalyticsEventTracker("Social");

  const fetchTraka = async () => {
    let response = await api.pravnaLica.fetchSponsoredCompanies(1);
    let data = await response.json();
    setLogos(data);
  };

  useEffect(() => {
    fetchTraka();
  }, []);

  const createStructuredJson = () => {
    let json = {
      "@context": "http://www.schema.org",
      "@type": "Organization",
      name: "Agencija Spektar",
      description: "Posredovanje pri zapošljavanju",
      url: window.location.href,
      email: "info@agencijaspektar.com",
      slogan: "Posredovanje pri zapošljavanju",
      telephone: "+387 51 321 930",
      foundingDate: "2001",
      founders: [
        {
          "@type": "Person",
          name: "Miroslav Vukajlović",
        },
      ],
      contactPoint: {
        "@type": "ContactPoint",
        contactType: "customer support",
        telephone: "[+387 51 321 930]",
        email: "info@agencijaspektar.com",
      },
      inLanguage: "bs-BA",
      keywords:
        "posao,zaposlenje,banja luka,banjaluka,zaposli se,tražim posao,radnici,poslodavci,bih,konkursi za posao,sezonski poslovi,inostranstvo,posao ponuda,ponuda poslova,posao od kuće,bosna i hercegovina,oglasi za posao,posao banja luka,honorarni posao,intervju,razgovor za posao,savjeti za intervju,savjeti za traženje posla,savjeti za posao,savjeti za razgovor za posao,posao banjaluka",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Bana Milosavljevića 8, 78000 Banja Luka",
        addressLocality: "Banja Luka",
        addressRegion: "Republika Srpska",
        postalCode: "78000",
        addressCountry: "Bosnia and Herzegovina",
      },
      sameAs: [
        "https://www.facebook.com/agencijaspektar",
        "https://www.instagram.com/agencijaspektar/",
        "https://www.linkedin.com/in/agencija-spektar/",
        "https://twitter.com/AgencijaSpektar",
        "https://www.youtube.com/@agencijaspektar",
      ],
      hasMap:
        "https://www.google.com/maps/place/Agencija+Spektar/@44.7730488,17.1924741,17z/data=!4m5!3m4!1s0x475e031a5bbbe917:0xb96ceb3c3bf9a18a!8m2!3d44.7728881!4d17.1933174",
      openingHours:
        "Mo 08:00-16:00 Tu 08:00-16:00 We 08:00-16:00 Th 08:30-16:00 Fr 08:00-16:00",
    };

    return JSON.stringify(json);
  };

  const onViberClick = () => {
    gaEventTracker("Viber Click", "Pridruživanje Viber grupi");
    window.open("https://bit.ly/2QPNCFf", "_blank");
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Kontakt | Agencija Spektar</title>
        <meta name="description" content="Agencija Spektar" />
        <meta property="og:title" content="Kontakt | Agencija Spektar" />
        <meta property="og:image" content={previewImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Agencija Spektar" />
        <script type="application/ld+json">{createStructuredJson()}</script>
      </Helmet>

      <div className={styles.aboutUs}>
        <div
          className={styles.blueBlob}
          style={{ left: "0", top: "70%", opacity: "0.2" }}
        ></div>
        <div
          className={styles.redBlob}
          style={{
            right: "4%",
            bottom: "50%",
            width: "150px",
            height: "150px",
            opacity: "0.6",
          }}
        ></div>
        <h1>O nama</h1>
        <p>
          Agencija Spektar je specijalizovana za pronalaženje i zapošljavanje
          stalno i privremeno angažovanog osoblja kako u Bosni i Hercegovini,
          tako i u inostranstvu. Kao kompanija posvećena postizanju izuzetnih
          rezultata na tržištu rada, zaslužili smo reputaciju uspješne agencije
          za posredovanje pri zapošljavanju osoblja i rukovodnog kadra u
          različitim privrednim granama.
        </p>
        <p>
          Naš cilj je od 2001. godine ostao isti – obezbijediti pouzdane i
          sposobne radnike svih profila. Naši klijenti kažu da saradnja sa nama
          liči na posjedovanje sopstvenog odjela za ljudske resurse koje
          obezbjeđuje kvalitetne kandidate na jeftin, efektivan i efikasan
          način.
        </p>
        <p>
          Naš tim se sastoji od iskusnih i motivisanih stručnjaka koji su
          sposobni da kompanijama svih veličina ponude usluge visokog kvaliteta
          u oblasti zapošljavanja. Fokus našeg tima je da za vaše poslovne
          probleme pronađe najbolja moguća rješenja.
        </p>
        <p>
          Kao kompanija sa najdužim iskustvom u oblasti posredovanja pri
          zapošljavanju u BiH, mi svojim klijentima nudimo lepezu posebno
          osmišljenih usluga iz ljudskih resursa. Mi takođe aktivno radimo sa
          kandidatima kroz pružanje specijalnih savjetodavnih usluga i obuka
          koje podižu njihovu konkurentnost na tržištu rada. Ovakvim pristupom
          istovremeno pomažemo kompanijama koje traže najbolje saradnike, ali i
          kandidatima.
        </p>
      </div>
      <div className={styles.cards}>
        <div className={classNames(styles.card)}>
          <h1>Zašto koristiti usluge agencije Spektar</h1>
          <p>
            Kada organizacije nisu u mogućnosti da samostalno pronađu saradnike,
            riješe organizacione probleme, obuče saradnike nekim posebnim
            znanjima i vještinama, najbolja opcija je angažovanje profesionalaca
            iz ovih oblasti. Originalnim rješenjima, u dvadesetogodišnjoj
            praksi, stekli smo povjerenje velikog broja klijenata, malih
            srednjih i velikih organizacija iz zemlje i svijeta.
          </p>
        </div>

        <div className={styles.services}>
          <div className={styles.servicesText}>
            <p
              style={{
                padding: "10px",
                borderLeft: "3px solid #F01D26",
                backgroundColor: "#F01D260d",
              }}
            >
              Agencija Spektar svim zainteresovanim klijentima nudi lepezu
              posebno osmišljenih usluga iz oblasti ljudskih resursa,
              savjetovanje na temu izgradnje sistema upravljanja, kao i niz
              različitih treninga za zaposlene.
            </p>
            <p
              style={{
                padding: "10px",
                borderLeft: "3px solid #00AADC",
                backgroundColor: "#00AADC0d",
              }}
            >
              Sarađujući sa nama, dobijate jedinstven pristup rješavanju raznih
              poslovnih problema.
            </p>
            <p
              style={{
                padding: "10px",
                borderLeft: "3px solid #8EC33B",
                backgroundColor: "#8EC33B0D",
              }}
            >
              Naše bogato iskustvo daje nam za pravo da se smatramo
              interesantnim partnerom u ovoj oblasti.
            </p>
          </div>
          <div className={styles.image}></div>
        </div>

        <LogoSlider logos={logos} />

        <div className={styles.viber}>
          <div className={styles.viberBackground}></div>
          <h1>Pridružite se Viber zajednici</h1>
          <p>
            Pridružite se Viber zajednici Agencije Spektar i budite svakodnevno
            informisani o najnovijim konkursima za posao. Putem ove platforme
            pored informacija o konkursima za posao, dostupna su i obavještenja
            o obukama, kursevima, savjetima za traženje posla i mnoge druge
            korisne informacije.
          </p>
          <Button
            type="default"
            style={{
              color: "white",
              borderColor: "#c4d1ff",
              width: "140px",
            }}
            onClick={onViberClick}
          >
            Pridruži me
          </Button>
        </div>

        <div className={styles.contact}>
          <div
            className={styles.yellowBlob}
            style={{ left: "0", top: "-200px", opacity: "0.2" }}
          ></div>
          <div
            className={styles.greenBlob}
            style={{ right: "0", top: "-500px", opacity: "0.2" }}
          ></div>
          <h1>Kontaktirajte nas</h1>

          <div className={styles.contacts}>
            <div className={styles.contactItem}>
              <div
                className={styles.icon}
                style={{ backgroundImage: `url(${phone})` }}
              ></div>
              <p style={{ textAlign: "left" }}>
                <span className={styles.span}>Telefon:</span> +387 51 321 930{" "}
                <br />
                <span className={styles.span}>Viber:</span> +387 65 200 444
                <br />
                <span className={styles.span}>Fax:</span> +387 51 321 931
              </p>
            </div>
            <div className={styles.contactItem}>
              <div
                className={styles.icon}
                style={{ backgroundImage: `url(${mail})` }}
              ></div>
              <p>info@agencijaspektar.com</p>
            </div>
            <div className={styles.contactItem}>
              <div
                className={styles.icon}
                style={{ backgroundImage: `url(${address})` }}
              ></div>
              <p>Bana Milosavljevića 8, 78000 Banja Luka</p>
            </div>
          </div>
          {/* <p>Pronađite nas na mapi</p> */}
          <div
            className={styles.map}
            onClick={() => window.open("https://bit.ly/3uma6jj", "_blank")}
          />

          <Divider className={styles.dividerRotated} />
        </div>

        <div className={styles.imgReveal}>
          <Footer />
        </div>
      </div>
    </div>
  );
};
