// Libs
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropzone from "react-dropzone";
import classNames from "classnames";

// Components
import { FormControl, TextField } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Button } from "../../../../components/Button/Button";
import { DateTimePicker } from "@mui/x-date-pickers";

// Utils
import styles from "./BlogForm.module.scss";
import api from "../../../../api";
import { Category } from "../../../../models/Category";
import { Subcategory } from "../../../../models/SubCategory";
import { Blog } from "../../../../models/Blog";

import { useAuth } from "../../../../contexts/auth-context";

interface BlogFormProps {
  blog?: Blog | null;
  className?: string;
}

type FormValues = {
  naslov: string;
  sadrzaj: string;
  fajl: File;
  vrijemeKreiranja: string;
};

const schema = yup.object().shape({
  naslov: yup.string().required("Polje je obavezno"),
  sadrzaj: yup.string().required("Polje je obavezno"),
  vrijemeKreiranja: yup.string(),
  fajl: yup.mixed(),
});

export const BlogForm: React.FunctionComponent<BlogFormProps> = ({
  blog,
  className,
}) => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const [mainCategories, setMainCategories] = useState<Category[]>([]);
  const [mainCategory, setMainCategory] = useState<Category | undefined>();
  const [subCategories, setSubCategories] = useState<Subcategory[]>([]);
  const [subcategory, setSubcategory] = useState<number | undefined>();

  const [preview, setPreview] = useState("");
  const [file, setFile] = useState<File | null>(null);

  const history = useHistory();

  const {
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    defaultValues: {
      naslov: blog?.naslov ?? "",
      sadrzaj: blog?.tekst ?? "",
      fajl: undefined,
      vrijemeKreiranja: blog?.vrijemeKreiranja
        ? moment(new Date(blog.vrijemeKreiranja)).format("YYYY-MM-DDTHH:mm:ss")
        : moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
    },
  });

  const onSubmit = async ({
    naslov,
    sadrzaj,
    fajl,
    vrijemeKreiranja,
  }: FormValues) => {
    const formData = new FormData();

    formData.append("slika", fajl ?? null);
    formData.append("naslov", naslov);
    formData.append("tekst", sadrzaj);
    formData.append("vrijemeKreiranja", vrijemeKreiranja);
    formData.append("idKategorije", subcategory!.toString());

    try {
      if (blog) {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + `admin/clanak/${blog?.id}`,
          {
            method: "put",
            body: formData,
            credentials: "include",
          }
        );

        if (response.status === 401) {
          setIsLoggedIn(false);
          history.push("/prijava");
        }
      } else {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + "admin/clanak",
          {
            method: "post",
            body: formData,
            credentials: "include",
          }
        );

        if (response.status === 401) {
          setIsLoggedIn(false);
          history.push("/prijava");
        }
      }
    } catch (err) {
      console.log(err);
    }

    reset();
    setMainCategory(undefined);
    setSubcategory(undefined);
    setPreview("");
    // setValue("vrijemeKreiranja", "");
    setFile(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const id = +event.target.value;
    setMainCategory(
      mainCategories
        .filter((e) => {
          return e.id === id;
        })
        .at(0)
    );
    fetchSubcategories(id);
  };

  const fetchCategories = async () => {
    try {
      const response = await api.categories.fetchMainCategories();
      if (response.status === 200) {
        const data = await response.json();
        setMainCategories(data);
      }
    } catch (err) {}
  };

  const fetchSubcategories = async (id: number) => {
    try {
      const response = await api.categories.fetchSubcategories(id);
      if (response.status === 200) {
        const data = await response.json();
        setSubCategories(data);
      } else if (response.status === 401) {
        setIsLoggedIn(false);
        history.push("/prijava");
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (file) setPreview(URL.createObjectURL(file));
  }, [file]);

  useEffect(() => {
    if (!isLoggedIn) history.push("/prijava");
    else fetchCategories();
  }, []);

  useEffect(() => {
    if (blog) setPreview(blog.slika);
  }, [blog]);

  return (
    <div className={styles.container}>
      <form
        className={classNames(styles.form, className)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.topInputs}>
          <FormControl style={{ width: "45%" }}>
            <InputLabel id="demo-simple-select-label">
              Glavna kategorija
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mainCategory?.naziv}
              label="Glavna kategorija"
              onChange={handleChange}
            >
              {mainCategories?.map((category) => (
                <MenuItem value={category.id}>
                  {category.naziv.replace("_", " ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: "45%" }}>
            <InputLabel id="category-label">Potkategorija</InputLabel>
            <Select
              labelId="category-label"
              disabled={subCategories.length === 0}
              id="category-label"
              value={subcategory}
              label="Potkategorija"
              onChange={(e) => {
                setSubcategory(+e.target.value);
              }}
            >
              {subCategories?.map((category) => (
                <MenuItem value={category.id}>
                  {category.naziv.replace("_", " ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Controller
            name="naslov"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                className={styles.input}
                id="outlined-required"
                fullWidth
                label="Naslov"
                placeholder="Naslov"
                error={!!errors.naslov}
                helperText={errors.naslov ? errors.naslov.message : ""}
              />
            )}
          />
          <Controller
            name="vrijemeKreiranja"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="DateTimePicker"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(
                    moment(newValue).format("YYYY-MM-DDTHH:mm:ss")
                  );
                }}
              />
            )}
          />
        </div>

        <Controller
          name="sadrzaj"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="outlined-textarea"
              label="Unesite tekst"
              placeholder="Sadržaj"
              multiline
              fullWidth
              rows={10}
              error={!!errors.sadrzaj}
              helperText={errors.sadrzaj ? errors.sadrzaj.message : ""}
            />
          )}
        />

        <Controller
          name="fajl"
          control={control}
          render={(props) => (
            <Dropzone
              onDrop={(acceptedFiles) => {
                setValue("fajl", acceptedFiles[0]);
                setFile(acceptedFiles[0]);
              }}
              {...props}
            >
              {({ getRootProps, getInputProps }) => (
                <section
                  className={styles.upload}
                  style={{
                    borderColor: errors.fajl && !file ? "red" : "#00aadc",
                  }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} name="fajl" />
                    {file || preview ? (
                      <>
                        {file && <p>{file.name}</p>}
                        <img
                          src={preview}
                          alt=""
                          style={{ height: "100px", margin: "auto" }}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <p>Prevucite dokument ili kliknite da izaberete.</p>
                        <UploadFileIcon
                          style={{ color: "#00aadc", margin: "auto" }}
                        />
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          )}
        />

        <Button type="submit" style={{ margin: "auto", width: "200px" }}>
          Unesi
        </Button>
      </form>
    </div>
  );
};
