import { JobCard } from "../../../components/JobCard/JobCard";
import { LoadingFallback } from "../../../components/LoadingFallback/LoadingFallback";
import { Konkurs } from "../../../models/Konkurs";
import styles from "./JobsGrid.module.scss";

interface JobsGridProps {
  konkursi: Konkurs[];
  loading: boolean;
  tag?: boolean;
}

export const JobsGrid: React.FunctionComponent<JobsGridProps> = ({
  loading,
  konkursi,
  tag = false,
}) => {
  return (
    <div className={styles.jobs}>
      {loading && <LoadingFallback />}
      {!loading && konkursi.length === 0 ? (
        <p style={{ marginTop: "auto", marginBottom: "auto" }}>
          Nisu pronađeni konkursi.
        </p>
      ) : (
        konkursi.length > 0 &&
        konkursi.map((e) => {
          return <JobCard konkurs={e} key={e.id} tag={tag} />;
        })
      )}
    </div>
  );
};
