// Libs
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// Components
import { Divider } from "../../components/Divider/Divider";
import { LogoSlider } from "../../components/LogoSlider/LogoSlider";
import { Footer } from "../../layouts/Footer/Footer";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Utils
import { faq } from "../../data/faq";
import styles from "./FAQPage.module.scss";
import { previewImage } from "../../constants";
import { Logo } from "../../api/services/pravna-lica";
import api from "../../api";

export const FAQPage: React.FunctionComponent = () => {
  const [expanded, setExpanded] = useState("");
  const [logos, setLogos] = useState<Logo[]>([]);

  const setExpandedPanel = (panel: string) => {
    if (panel === expanded) setExpanded("");
    else setExpanded(panel);
  };

  const fetchTraka = async () => {
    let response = await api.pravnaLica.fetchSponsoredCompanies(1);
    let data = await response.json();
    setLogos(data);
  };

  useEffect(() => {
    fetchTraka();
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Najčešća pitanja | Agencija Spektar</title>
        <meta name="description" content="Agencija Spektar" />
        <meta
          property="og:title"
          content="Najčešća pitanja | Agencija Spektar"
        />
        <meta property="og:image" content={previewImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Agencija Spektar" />
      </Helmet>

      <div className={styles.header}>
        <h1 className={styles.title}>Najčešća pitanja</h1>
        <Divider className={styles.divider} />
      </div>

      <div className={styles.content}>
        {faq.map((question) => {
          return (
            <Accordion
              key={question.q}
              style={{ margin: "8px 0" }}
              onClick={() => setExpandedPanel(`panel${question.id}a-header`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${question.id}a-content`}
                id={`panel${question.id}a-header`}
                style={{ backgroundColor: "#3861ED0D" }}
              >
                <Typography
                  style={{
                    fontWeight: "700",
                    color:
                      expanded === `panel${question.id}a-header`
                        ? "#3861ed"
                        : "#707070",
                    lineHeight: "38px",
                    fontSize: "18px",
                    fontFamily: "Open Sans",
                  }}
                >
                  {question.q}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ margin: "10px", fontSize: "17px" }}>
                  {question.a}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>

      <LogoSlider logos={logos} />

      <div className={styles.imgReveal}>
        <Divider className={styles.footerDivider} />
        <Footer />
      </div>
    </div>
  );
};
