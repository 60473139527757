import { NavLink, NavLinkProps } from "react-router-dom";
import styles from "./Link.module.scss";

interface LinkProps extends NavLinkProps {}

export const Link: React.FunctionComponent<LinkProps> = ({
  children,
  ...props
}) => {
  return (
    <NavLink
      exact
      className={styles.link}
      style={(isActive) => ({
        color: isActive ? "#3861ed" : "",
      })}
      {...props}
    >
      {children}
    </NavLink>
  );
};
