import { get, post } from "../client";

const requestOptions = {
  headers: {
    authorization: "Basic " + process.env.REACT_APP_AUTH_KEY,
  },
};

const fetchAll = async (type: string) => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL + "konkursi/" + type,
    requestOptions
  );
  return response;
};

const search = async (
  query: string,
  location: string,
  tip: string = "",
  arhiva: boolean = false
) => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL +
      `konkursi/search?pojam=${query}&lokacija=${location}&arhiva=${arhiva}&tip=${tip}`,
    requestOptions
  );
  return response;
};

const fetchById = async (id: string) => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL + "konkursi/" + id,
    requestOptions
  );
  return response;
};

interface AdminKonkursiParams {
  title?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  sort?: string;
}

const fetchAllForAdmin = async ({
  title,
  startDate,
  endDate,
  page,
  sort,
}: AdminKonkursiParams) => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL +
      `admin/konkursi?title=${title}&startDate=${startDate}&endDate=${endDate}&page=${page}&sort=${sort}`,
    requestOptions
  );
  return response;
};

const applyForJob = async (id: string, body: FormData) => {
  const response = await post(
    process.env.REACT_APP_BACKEND_URL + "konkursi/prijava?idKonkursa=" + id,
    { ...requestOptions, body }
  );
  return response;
};

const scheduleAppointment = async (body: FormData) => {
  const response = await post(
    process.env.REACT_APP_BACKEND_URL + "konkursi/prijava",
    { ...requestOptions, body }
  );
  return response;
};

const fetchPremium = async () => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL + "konkursi/premium",
    requestOptions
  );
  return response;
};

const newsletter = async (body: FormData) => {
  const response = await post(
    process.env.REACT_APP_BACKEND_URL + "konkursi/pretplata",
    { ...requestOptions, body }
  );

  return response;
};

export {
  fetchAll,
  search,
  fetchById,
  fetchPremium,
  applyForJob,
  scheduleAppointment,
  newsletter,
  fetchAllForAdmin,
};
