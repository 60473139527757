import { CSSProperties } from "react";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/linkedin.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Viber } from "../../assets/icons/viber-icon.svg";

import {
  FACEBOOK_SHARE,
  LINKEDIN_SHARE,
  TWITTER_SHARE,
  VIBER_SHARE,
} from "../../constants";

import styles from "./SocialsShare.module.scss";

interface SocialsShareProps {
  text: string;
  url: string;
  style?: CSSProperties;
}

export const SocialsShare: React.FunctionComponent<SocialsShareProps> = ({
  text,
  url,
  style,
}) => {
  return (
    <div className={styles.share} style={style}>
      <p className={styles.share__text}>{text}</p>
      <div className={styles.socials}>
        <a
          className={styles.iconWrapper}
          href={FACEBOOK_SHARE + url}
          target="_blank"
          rel="noreferrer"
        >
          <Facebook />
        </a>
        <a
          className={styles.iconWrapper}
          href={LINKEDIN_SHARE + url}
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin />
        </a>
        <a
          className={styles.iconWrapper}
          href={TWITTER_SHARE + url}
          target="_blank"
          rel="noreferrer"
        >
          <Twitter />
        </a>
        <a
          className={styles.iconWrapper}
          href={VIBER_SHARE + url}
          target="_blank"
          rel="noreferrer"
        >
          <Viber className={styles.viber} />
        </a>
      </div>
    </div>
  );
};
