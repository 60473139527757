// Libs
import { BrowserRouter as Router, Switch } from "react-router-dom";

// Layouts
import AppLayout from "../layouts/AppLayout/AppLayout";
import CenterLayout from "../layouts/CenterLayout/CenterLayout";
import AdminLayout from "../pages/AdminPage/AdminLayout";

// Pages
import { BlogForm } from "../pages/AdminPage/BlogsView/BlogForm/BlogForm";
import { BlogsView } from "../pages/AdminPage/BlogsView/BlogsView";
import { CompaniesView } from "../pages/AdminPage/CompaniesView/CompaniesView";
import { SponsoredView } from "../pages/AdminPage/SponsoredView/SponsoredView";
import { AdvicesPage } from "../pages/AdvicesPage/AdvicesPage";
import { AppointmentPage } from "../pages/AppointmentPage/AppointmentPage";
import { ArchivePage } from "../pages/ArchivePage/ArchivePage";
import { BlogArchive } from "../pages/BlogArchive/BlogArchive";
import { BlogPage } from "../pages/BlogPage/BlogPage";
import { CareerAdvicesPage } from "../pages/CareerAdvicePage/CareerAdvicePage";
import { ConsultingPage } from "../pages/ConsultingPage/ConsultingPage";
import { ContactPage } from "../pages/ContactPage/ContactPage";
import { FAQPage } from "../pages/FAQPage/FAQPage";
import { InterviewPage } from "../pages/InterviewPage/InterviewPage";
import { JobPage } from "../pages/JobPage/JobPage";
import { JobsPage } from "../pages/JobsPage/JobsPage";
import { LandingPage } from "../pages/LandingPage/LandingPage";
import { LoginPage } from "../pages/LoginPage/LoginPage";
import { PostPage } from "../pages/PostPage/PostPage";
import { PrivacyPolicy } from "../pages/PrivacyPolicy/PrivacyPolicy";
import { ProjectsPage } from "../pages/ProjectsPage/ProjectsPage";
import { PublicJobsPage } from "../pages/PublicJobsPage/PublicJobsPage";
import { ServicesPage } from "../pages/ServicesPage/ServicesPage";
import { StudentServicePage } from "../pages/StudentServicePage/StudentServicePage";
import { TrainingsPage } from "../pages/TrainingsPage/TrainingsPage";
import { VideoPage } from "../pages/VideoPage/VideoPage";
import { RouteWrapper } from "./RouteWrapper";
import { AdminJobsView } from "../pages/AdminPage/AdminJobsView/AdminJobsView";

export const Routes: React.FunctionComponent = () => {
  return (
    <Router>
      <Switch>
        <RouteWrapper
          exact
          path="/"
          component={LandingPage}
          layout={AppLayout}
        />

        <RouteWrapper
          exact
          path="/pretraga?q=:query&l=:location"
          component={LandingPage}
          layout={AppLayout}
        />

        <RouteWrapper
          exact
          path="/svi-poslovi/:page"
          component={JobsPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/oglasi-iz-sredstava-informisanja/:page"
          component={PublicJobsPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/posao/:id/:name"
          component={JobPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/pripremite-se-za-intervju"
          component={InterviewPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/korisni-savjeti"
          component={AdvicesPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/pitanja"
          component={FAQPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/obuke"
          component={TrainingsPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/studentski-servis"
          component={StudentServicePage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/poslovno-savjetovanje"
          component={ConsultingPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/karijerno-savjetovanje"
          component={CareerAdvicesPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/trazite-radnike"
          component={ServicesPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/arhiva/:page"
          component={ArchivePage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/blog/:page"
          component={BlogPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/kontakt"
          component={ContactPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/zakazi-razgovor"
          component={AppointmentPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/objava/:id/:name"
          component={PostPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/politika-privatnosti"
          component={PrivacyPolicy}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/mediji/:page"
          component={BlogArchive}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/video-materijali"
          component={VideoPage}
          layout={AppLayout}
        />
        <RouteWrapper
          exact
          path="/projekti"
          component={ProjectsPage}
          layout={AppLayout}
        />

        <RouteWrapper
          exact
          path="/prijava"
          component={LoginPage}
          layout={CenterLayout}
        />
        <RouteWrapper
          exact
          path="/admin"
          component={LoginPage}
          layout={CenterLayout}
        />
        <RouteWrapper
          exact
          path="/admin/blogovi"
          component={BlogsView}
          layout={AdminLayout}
        />
        <RouteWrapper
          exact
          path="/admin/blogovi/novi-clanak"
          component={BlogForm}
          layout={AdminLayout}
        />
        <RouteWrapper
          exact
          path="/admin/pravna-lica"
          component={CompaniesView}
          layout={AdminLayout}
        />
        <RouteWrapper
          exact
          path="/admin/sponzorisana-pravna-lica"
          component={SponsoredView}
          layout={AdminLayout}
        />
        <RouteWrapper
          exact
          path="/admin/konkursi"
          component={AdminJobsView}
          layout={AdminLayout}
        />

        <RouteWrapper
          exact
          path="*"
          component={LandingPage}
          layout={AppLayout}
        />
      </Switch>
    </Router>
  );
};
