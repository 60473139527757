// Libs
import React, { useRef, useState } from "react";
import classNames from "classnames";

// Hooks
import { useOnHoverOutside } from "../../hooks/useOnHoverOutside";

import styles from "./DropdownMenu.module.scss";

export interface DropdownMenuProps {
  text: string;
  children?: React.ReactNode;
}

export const DropdownMenu = ({ text, children }: DropdownMenuProps) => {
  const dropdownRef = useRef(null);
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

  const closeHoverMenu = () => {
    setMenuDropDownOpen(false);
  };

  useOnHoverOutside(dropdownRef, closeHoverMenu);

  return (
    <div
      className={styles.wrapper}
      ref={dropdownRef}
      onMouseOver={() => setMenuDropDownOpen(true)}
    >
      <div className={styles.text}>
        <p>{text}</p>
        <div
          className={classNames(
            styles.icon,
            isMenuDropDownOpen ? styles.arrowDown : styles.arrowUp
          )}
        ></div>
      </div>

      <div
        className={classNames(
          styles.container,
          isMenuDropDownOpen ? styles.display : styles.hidden
        )}
      >
        {isMenuDropDownOpen && children}
      </div>
    </div>
  );
};
