import styles from "./JobPage.module.scss";

// Libs
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// Components
import { LogoSlider } from "../../components/LogoSlider/LogoSlider";
import { Divider } from "../../components/Divider/Divider";
import { Footer } from "../../layouts/Footer/Footer";
import { JobCard } from "../../components/JobCard/JobCard";
import { ApplicationForm } from "../../components/ApplicationForm/ApplicationForm";
import { SocialsShare } from "../../components/SocialShare/SocialsShare";
import { LoadingFallback } from "../../components/LoadingFallback/LoadingFallback";
import { NewsletterForm } from "./NewsletterForm/NewsletterForm";

// Utils
import { formatDate } from "../../util";
import api from "../../api";
import { KonkursResponse } from "../../models/KonkursResponse";
import { Logo } from "../../api/services/pravna-lica";

// Assets
import { ReactComponent as Location } from "../../assets/icons/pin.svg";
import { ReactComponent as Users } from "../../assets/icons/users.svg";
import { ReactComponent as PublishDate } from "../../assets/icons/time-check.svg";
import { ReactComponent as DueDate } from "../../assets/icons/time-delete.svg";
import { ReactComponent as BackArrow } from "../../assets/icons/arrow-thick.svg";
import DownloadIcon from "@mui/icons-material/Download";
import defaultLogo from "../../assets/logos/logo.png";

interface PathParams {
  id: string;
  name: string;
}

export const JobPage: React.FunctionComponent = () => {
  const [konkurs, setKonkurs] = useState<KonkursResponse | undefined>();
  const [expired, setExpired] = useState(false);
  const history = useHistory();
  const { id, name } = useParams<PathParams>();
  const [loading, setLoading] = useState(false);
  const [logos, setLogos] = useState<Logo[]>([]);

  const pathname = window.location.href;

  const showForm =
    konkurs?.konkurs.tip !== 3 &&
    !expired &&
    konkurs?.konkurs.webPrijavaOmogucena;

  const fetchKonkurs = async (id: string) => {
    setLoading(true);
    try {
      const response = await api.konkursi.fetchById(id!);
      const data = await response.json();
      setKonkurs(data);
      document.title = data.konkurs.naslov;

      setExpired(
        new Date(data.konkurs.rokPrijava).getTime() < new Date().getTime()
      );

      setLoading(false);
    } catch (err) {}
  };

  const onBackClick = () => {
    history.goBack();
  };

  const onCompanyLogoClick = () => {
    // let link = konkurs?.pravnoLice.link.startsWith("https://www")
    //   ? konkurs.pravnoLice.link
    //   : "https://www." + konkurs?.pravnoLice.link;

    window.open(
      konkurs?.pravnoLice.link ?? "https://agencijaspektar.com/svi-poslovi/1"
    );
  };

  const createStructuredJson = () => {
    if (konkurs) {
      let json = {
        "@context": "https://schema.org",
        "@type": "JobPosting",
        applicationContact: {
          "@type": "ContactPoint",
          email: "info@agencijaspektar.com",
        },
        datePosted: konkurs?.konkurs.datumObjave,
        jobLocation: konkurs?.konkurs.lokacija,
        title: konkurs?.konkurs.naslov,
        totalJobOpenings: konkurs?.konkurs.brojIzvrsilaca,
        validThrough: konkurs?.konkurs.rokPrijava,
        image: konkurs?.pravnoLice.slika,
        url: window.location.href,
        name: konkurs.konkurs.naslov,
      };

      return JSON.stringify(json);
    }
  };

  const fetchTraka = async () => {
    let response = await api.pravnaLica.fetchSponsoredCompanies(1);
    let data = await response.json();
    setLogos(data);
  };

  useEffect(() => {
    fetchKonkurs(id);
  }, [name, id]);

  useEffect(() => {
    fetchTraka();
  }, []);

  return (
    <div className={styles.container}>
      {konkurs && (
        <Helmet>
          <title>{konkurs.konkurs.naslov}</title>
          <meta
            name="image"
            property="og:image"
            content={konkurs.pravnoLice.slika + "?w=800"}
          />
          <meta
            name="description"
            content={`Konkurs za posao ${konkurs.konkurs.naslov}`}
          />

          <meta property="og:title" content={konkurs.konkurs.naslov} />
          <meta
            property="og:description"
            content={`Konkurs za posao ${konkurs.konkurs.naslov}`}
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Agencija Spektar" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={konkurs.konkurs.naslov} />
          <meta
            name="twitter:description"
            content={`Konkurs za posao ${konkurs.konkurs.naslov}`}
          />
          <meta
            name="twitter:image"
            content={konkurs.pravnoLice.slika + "?w=800"}
          />

          <script type="application/ld+json">{createStructuredJson()}</script>
        </Helmet>
      )}

      {loading ? (
        <LoadingFallback />
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.back} onClick={onBackClick}>
              <BackArrow />
            </div>
            <div className={styles.subheading}>
              <p>{konkurs?.konkurs.zaglavlje ?? konkurs?.pravnoLice.tekst}</p>
            </div>
            <div className={styles.title}>
              <h1>{konkurs?.konkurs.naslov}</h1>
            </div>
          </div>
          <div className={styles.infoSection}>
            <div
              className={styles.logo}
              style={{
                backgroundImage: konkurs?.pravnoLice.slika
                  ? `url(${konkurs?.pravnoLice.slika})`
                  : defaultLogo,
                padding: "5px",
              }}
              onClick={onCompanyLogoClick}
            />
            <div className={styles.info}>
              <div className={styles.infoWrapper}>
                <div className={styles.infoHeading}>
                  <Location /> Lokacija
                </div>
                <p className={styles.infoText}>{konkurs?.konkurs.lokacija}</p>
              </div>
              <div className={styles.infoWrapper}>
                <div className={styles.infoHeading}>
                  <Users /> Broj izvršilaca
                </div>
                <p className={styles.infoText}>
                  {konkurs?.konkurs.brojIzvrsilaca}
                </p>
              </div>
              <div className={styles.infoWrapper}>
                <div className={styles.infoHeading}>
                  <PublishDate /> Datum objave
                </div>
                <p className={styles.infoText}>
                  {formatDate(konkurs?.konkurs.datumObjave) || ""}.
                </p>
              </div>
              <div className={styles.infoWrapper}>
                <div className={styles.infoHeading}>
                  <DueDate /> Datum isteka
                </div>
                <p className={styles.infoText}>
                  {formatDate(konkurs?.konkurs.rokPrijava) || ""}.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.content}>
            {expired && <div className={styles.tag}>Oglas je istekao! </div>}

            <div
              dangerouslySetInnerHTML={{
                __html: konkurs?.konkurs.sadrzaj || "",
              }}
              className={styles.oglas}
            />

            <SocialsShare text="Podijelite ovaj oglas:" url={pathname} />
          </div>

          {showForm && <ApplicationForm id={id} />}

          <div className={styles.note}>
            <h1>Tražite posao?</h1>
            <p>
              Prijavite se na naš newsletter kako biste bili u toku sa aktuelnim
              radnim mjestima i ostalim zanimljivostima vezanim za
              zapošljavanje.
            </p>
            <p>
              Odjaviti se možete u bilo kojem trenutku koristeći link za odjavu
              u e-mailu.
            </p>
            <p>
              Više informacija možete pronaći u našoj{" "}
              <a
                href="https://www.agencijaspektar.com/politika-privatnosti"
                target="_blank"
              >
                politici privatnosti.
              </a>
            </p>

            <NewsletterForm />
          </div>

          <div className={styles.templates}>
            <a
              href="https://test.agencijaspektar.com:9000/cvobrazac/CvObrazac.docx"
              download
            >
              CV obrazac <DownloadIcon />
            </a>
          </div>

          <LogoSlider logos={logos} />

          {konkurs && konkurs.slicniKonkursi.length > 0 && (
            <div className={styles.similarJobs}>
              <h1
                style={{
                  width: "80%",
                  marginBottom: "0",
                  color: " #e2e8fd",
                  textAlign: "center",
                }}
              >
                Slični poslovi
              </h1>
              {konkurs.slicniKonkursi?.map((e) => {
                return <JobCard konkurs={e} key={e.id} />;
              })}
            </div>
          )}

          <div className={styles.note}>
            <h1>Napomena</h1>
            <p>
              Oglasi koje objavljujemo na web stranici
              <a href="/" target="_blank">
                {" "}
                www.agencijaspektar.com{" "}
              </a>
              su podijeljeni u dvije grupe: oglase Agencije i one koje
              preuzimamo iz sredstava javnog informisanja.
            </p>
            <p>
              Oglasi objavljeni pod logom Agencije Spektar, kao i komercijalni
              oglasi objavljeni pod logom naših Klijenata su autorsko pravo
              Agencije Spektar. Zabranjeno je kopiranje teksta oglasa.
            </p>
            <p>
              Objavljivanjem oglasa podliježete pravnim posljedicama, odnosno
              naknadi nastale štete.
            </p>
            <p>
              Oglasi objavljeni iz sredstava javnog informisanja su preneseni u
              obliku u kojem su objavljeni u novinama, te nismo u mogućnosti da
              garantujemo tačnost istih ukoliko u originalnoj formi (u medijijma
              u kojima je objavljen) nije tačno prenesen, što nema povezanosti
              sa radom zaposlenih Agencije. Savjetujemo svim kandidatima da
              provjere iste kod poslodavca koji oglašava potrebu za radnicima
              jer se uslovi konkursa mogu promijeniti u međuvremenu.
            </p>
            <Divider className={styles.dividerRotated} />
          </div>

          <div className={styles.imgReveal}>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};
