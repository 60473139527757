// Libs
import urlSlug from "url-slug";

// Models
import { Blog } from "../../models/Blog";

// Utils
import { getText } from "../../util";

import styles from "./BlogCard.module.scss";

export type StudentBlogType =
  | "OBUKE"
  | "POVREMENI"
  | "VOLONTIRANJA"
  | "PRAKSE"
  | "STIPENDIJE"
  | "INOSTRANSTVO";

const blogTypes: Record<string, string> = {
  OBUKE: "#3861ED",
  POVREMENI: "#ddd200",
  VOLONTIRANJA: "#F5811D",
  PRAKSE: "#00AADC",
  STIPENDIJE: "#9BD4ED",
  INOSTRANSTVO: "#8EC33B",
};

export interface BlogCardProps {
  blog: Blog;
  type?: StudentBlogType;
  tag?: boolean;
}

export const BlogCard: React.FunctionComponent<BlogCardProps> = ({
  blog,
  tag = false,
}) => {
  return (
    <a
      className={styles.container}
      href={"/objava/" + blog.id + "/" + urlSlug(blog.naslov)}
    >
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${blog.slika})`,
        }}
      ></div>
      <div className={styles.content}>
        {tag && (
          <div
            className={styles.type}
            style={{
              background:
                "linear-gradient(to bottom," +
                blogTypes[blog.kategorija.naziv] +
                ", " +
                blogTypes[blog.kategorija.naziv] +
                "80)",
              color: "white",
            }}
          >
            {blog.kategorija.naziv}
          </div>
        )}

        <h1 className={styles.title}>{blog.naslov}</h1>
        <p className={styles.summary}>{getText(blog.tekst)}</p>
      </div>

      <div className={styles.more}>
        {!blog.aktivan && (
          <p className={styles.inactive}>Prilika više nije aktivna</p>
        )}
        <p className={styles.moreText}>Opširnije</p>
      </div>
    </a>
  );
};
