// Libs
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// Components
import { BlogCard } from "../../components/BlogCard/BlogCard";
import { Divider } from "../../components/Divider/Divider";
import { LoadingFallback } from "../../components/LoadingFallback/LoadingFallback";
import { LogoSlider } from "../../components/LogoSlider/LogoSlider";
import { Tag } from "../../components/Tag/Tag";
import { Footer } from "../../layouts/Footer/Footer";

// Utils
import api from "../../api";
import { Blog } from "../../models/Blog";

import styles from "./StudentServicePage.module.scss";
import { previewImage } from "../../constants";
import { Logo } from "../../api/services/pravna-lica";

export const StudentServicePage: React.FunctionComponent = () => {
  const [posts, setPosts] = useState<Blog[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<Blog[]>([]);
  const [loading, setLoading] = useState(false);

  const [logos, setLogos] = useState<Logo[]>([]);

  const fetchTraka = async () => {
    let response = await api.pravnaLica.fetchSponsoredCompanies(1);
    let data = await response.json();
    setLogos(data);
  };

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await api.blogs.fetchBlogs("1");
      const data = await response.json();
      if (response.status === 200) {
        setPosts(data);
        setFilteredPosts(data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filterPosts = (key: string) => {
    if (key === "CLEAR") {
      setFilteredPosts(posts);
    } else {
      let filtered = posts.filter((e) => e.kategorija.naziv.includes(key));
      setFilteredPosts(filtered);
    }
  };

  useEffect(() => {
    fetchPosts();
    fetchTraka();
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Studentski servis | Agencija Spektar</title>
        <meta name="description" content="Agencija Spektar" />
        <meta
          property="og:title"
          content="Studentski servis | Agencija Spektar"
        />
        <meta property="og:image" content={previewImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Agencija Spektar" />
      </Helmet>

      <div className={styles.text}>
        <h1 className={styles.title}>Studentski servis</h1>
        <p className={styles.description}>
          Pronađite zanimljive i aktuelne informacije u vezi povremenih poslova,
          studentskih praksi, studentskih volontiranja, studentskog rada u
          inostranstvu, kao i interesantnih obuka/treninga namjenjenih za
          studente.
        </p>
      </div>

      <div className={styles.tags}>
        <Tag
          text="sve objave"
          color="#F01D26"
          type="CLEAR"
          action={filterPosts}
        />
        <Tag
          text="obuke i treninzi"
          color="#3861ED"
          type="OBUKE"
          action={filterPosts}
        />
        <Tag
          text="povremeni poslovi"
          color="#ddd200"
          type="POVREMENI"
          action={filterPosts}
        />
        <Tag
          text="volontiranja"
          color="#F5811D"
          type="VOLONTIRANJA"
          action={filterPosts}
        />
        <Tag text="prakse" color="#00AADC" type="PRAKSE" action={filterPosts} />
        <Tag
          text="stipendije"
          color="#9BD4ED"
          type="STIPENDIJE"
          action={filterPosts}
        />
        <Tag
          text="prilike u inostranstvu"
          color="#8EC33B"
          type="INOSTRANSTVO"
          action={filterPosts}
        />
      </div>

      <div className={styles.cards}>
        {loading && <LoadingFallback />}
        {!loading && filteredPosts.length === 0 ? (
          <p className={styles.center}>Nema objava za traženu kategoriju.</p>
        ) : (
          filteredPosts.map((e) => {
            return <BlogCard blog={e} key={e.id} tag={true} />;
          })
        )}
      </div>

      <div className={styles.section}>
        <LogoSlider logos={logos} />
        <Divider className={styles.dividerRotated} />
      </div>

      <div className={styles.imgReveal}>
        <Footer />
      </div>
    </div>
  );
};
