import styles from "./Space.module.scss";

type NativeProps = React.HtmlHTMLAttributes<HTMLDivElement>;

interface SpaceProps extends NativeProps {
  children: React.ReactNode;
}

export const Space: React.FunctionComponent<SpaceProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};
