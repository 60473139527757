// Libs
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  getGridDateOperators,
  getGridStringOperators,
} from "@mui/x-data-grid";

// Contexts
import { useAuth } from "../../../contexts/auth-context";

// Utils
import api from "../../../api";

import styles from "./AdminJobsView.module.scss";
import { Box, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

type Order = "asc" | "desc";
const rowsPerPage = 20;

export interface Konkurs {
  id: number;
  naslov: string;
  datumObjave: string;
  brojCitanja: number;
}

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ["contains"].includes(value)
);

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 150, filterable: false },
  { field: "naslov", headerName: "Naslov", width: 600, filterOperators },
  {
    field: "brojCitanja",
    headerName: "Broj citanja",
    width: 150,
    filterable: false,
  },
];

interface DateRangeInputProps {
  startDate: Date | string;
  setStartDate: (date?: string | null) => void;
  endDate: Date | string;
  setEndDate: (date?: string | null) => void;
}

function DateRangeInput({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: DateRangeInputProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 48,
        pl: "20px",
      }}
    >
      <DatePicker
        label="Od"
        renderInput={(params) => <TextField {...params} size="small" />}
        value={startDate}
        onChange={setStartDate}
      />
      <DatePicker
        label="Do"
        renderInput={(params) => <TextField {...params} size="small" />}
        value={endDate}
        onChange={setEndDate}
      />
    </Box>
  );
}

export const AdminJobsView: React.FunctionComponent = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const [page, setPage] = useState(0);
  const [data, setData] = useState<any>();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "datumObjave",
      sort: "desc",
    },
  ]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      { field: "naslov", operator: "contains", value: "" },
      { field: "datumObjave", operator: "contains", value: new Date() },
    ],
  });

  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState<string | undefined>("");
  const [endDate, setEndDate] = useState<string | undefined>("");
  const [sort, setSort] = useState("desc");

  const [orderBy, setOrderBy] = useState("datum_objave");
  const [order, setOrder] = useState<Order>("desc");

  const [konkursi, setKonkursi] = useState<Konkurs[]>([]);

  const history = useHistory();

  const fetchData = async () => {
    let response = await api.konkursi.fetchAllForAdmin({
      title,
      startDate,
      endDate,
      sort,
      page,
    });

    const data = await response.json();
    setData(data);

    setPage(data.number);
    setKonkursi(data.content);
  };

  const handleFilterModelChange = useCallback((model: GridFilterModel) => {
    if (!model.items.length) {
      setTitle("");
      setStartDate("");
      setEndDate("");
      return;
    }

    const field = model.items[0].field;
    const value = model.items[0].value;

    setFilterModel(model);

    if (!value) {
      setTitle("");
      return;
    }

    if (field === "naslov") {
      setTitle(value);
    } else if (field === "datumObjave") {
      setStartDate(value);
    }
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    let field = sortModel[0].field;
    let order = sortModel[0].sort;

    setOrder(order as Order);
    setOrderBy(field);

    setSortModel(sortModel);

    let sort;

    if (field === "datumObjave") {
      sort = "datum_objave";
    } else if (field === "brojCitanja") {
      sort = "broj_citanja";
    } else {
      sort = field;
    }

    setSort(`${sort},${order}`);
  }, []);

  const onStartDateSelect = (date?: string | null) => {
    if (date) {
      setStartDate(new Date(date).toLocaleDateString("fr-CA"));
    } else {
      setStartDate("");
    }
  };

  const onEndDateSelect = (date?: string | null) => {
    if (date) {
      setEndDate(new Date(date).toLocaleDateString("fr-CA"));
    } else {
      setEndDate("");
    }
  };

  useEffect(() => {
    if (!isLoggedIn) history.push("/prijava");
    else fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, title, startDate, endDate, sort]);

  return (
    <div className={styles.container}>
      <DataGrid
        rows={konkursi}
        columns={[
          ...columns,
          {
            field: "datumObjave",
            headerName: "Datum objave",
            width: 250,
            type: "date",
            valueFormatter: ({ value }) =>
              new Date(value as string).toLocaleDateString("sr-RS", {
                hour: "2-digit",
                minute: "2-digit",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }),

            filterOperators: getGridDateOperators()
              .filter(({ value }) => ["is"].includes(value))
              .map((operator) => {
                return {
                  ...operator,

                  InputComponent: () => (
                    <DateRangeInput
                      startDate={startDate!}
                      endDate={endDate!}
                      setEndDate={(value) => onEndDateSelect(value)}
                      setStartDate={(value) => onStartDateSelect(value)}
                    />
                  ),
                };
              }),
          },
        ]}
        style={{ background: "#fff" }}
        hideFooterSelectedRowCount={true}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: rowsPerPage,
            },
          },
        }}
        paginationModel={{
          page: page,
          pageSize: rowsPerPage,
        }}
        paginationMode="server"
        onPaginationModelChange={(value) => {
          setPage(value.page);
        }}
        rowCount={data?.totalElements ?? 20}
        sortingMode="server"
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
      />
    </div>
  );
};
