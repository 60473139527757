import { useState } from "react";

//Components
import { Blog } from "../../../../models/Blog";
import { Switch } from "@mui/material";

// Assets
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

import { formatDate, getText } from "../../../../util";
import styles from "./BlogPost.module.scss";

interface BlogPostProps {
  blog: Blog;
  onActiveChange: (id: number) => void;
  onPremiumChange: (id: number) => void;
  onDelete: (id: number) => void;
  onEdit: (blog: Blog) => void;
}

export const BlogPost: React.FunctionComponent<BlogPostProps> = ({
  blog,
  onActiveChange,
  onPremiumChange,
  onDelete,
  onEdit,
}) => {
  const [isActive, setIsActive] = useState(blog.aktivan);
  const [isPremium, setIsPremium] = useState(blog.premium);

  const handleActiveChange = () => {
    setIsActive(!isActive);
    onActiveChange(blog.id);
  };

  const handlePremiumChange = () => {
    setIsPremium(!isPremium);
    onPremiumChange(blog.id);
  };

  return (
    <div className={styles.blogPost}>
      <img src={blog.slika} alt="" className={styles.img} />
      <div className={styles.content}>
        <h1 className={styles.title}>{blog.naslov}</h1>
        <p className={styles.date}>{formatDate(blog.vrijemeKreiranja)}.</p>
        <p className={styles.description}>{getText(blog.tekst)}</p>
        <div className={styles.actions}>
          {blog.kategorija.idGlavneKategorije === 1 && (
            <div>
              <label>Aktivan:</label>
              <Switch checked={isActive} onChange={handleActiveChange} />
            </div>
          )}

          <div>
            <label>Premium:</label>
            <Switch checked={isPremium} onChange={handlePremiumChange} />
          </div>

          <div style={{ marginLeft: "auto" }}>
            <IconButton aria-label="delete" onClick={() => onEdit(blog)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => onDelete(blog.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};
