// Libs
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Components
import { LogoSlider } from "../../components/LogoSlider/LogoSlider";
import { Search } from "../../features/Search/Search";
import { Konkurs } from "../../models/Konkurs";
import Pagination from "@mui/material/Pagination";
import { Footer } from "../../layouts/Footer/Footer";
import { Divider } from "../../components/Divider/Divider";
import { JobsGrid } from "./JobsGrid/JobsGrid";
import { PremiumJobs } from "../../containers/PremiumJobs";

// Utils
import styles from "./JobsPage.module.scss";
import { PageableResponse } from "../../models/PageableResponse";
import api from "../../api";
import { previewImage } from "../../constants";
import { Logo } from "../../api/services/pravna-lica";

const PAGE_SIZE = 24;

interface PageParams {
  page: string;
}

export const JobsPage = () => {
  const { page } = useParams<PageParams>();
  const [konkursi, setKonkursi] = useState<Konkurs[]>([]);
  const location = useLocation();

  const hasQueryParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.has("q") || params.has("l");
  }, [location.search]);

  const queryParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const searchParam = params.get("q");
    const locationParam = params.get("l");

    return { searchParam, locationParam };
  }, [location.search]);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PageableResponse>();
  const [showPagination, setShowPagination] = useState(true);
  const [logos, setLogos] = useState<Logo[]>([]);

  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const fetchJobs = useCallback(async (page: number, size: number) => {
    setLoading(true);
    try {
      const response = await api.konkursi.fetchAll(
        `svi-privatni?size=${size}&page=${page}`
      );
      if (response.status === 200) {
        const data = await response.json();
        setPagination(data);
        setKonkursi(data.content);
        setLoading(false);
        setShowPagination(true);
      }
    } catch (err) {}
  }, []);

  const searchHandler = useCallback(
    async (query: string, location: string, shouldNavigate = true) => {
      const response = await api.konkursi.search(query, location, "0");
      const data: Konkurs[] = await response.json();
      const konkursiFiltered = data.filter((e) => e.idPravnogLica !== null);

      setShowPagination(false);
      setKonkursi(konkursiFiltered);

      if (shouldNavigate) {
        history.push(`/svi-poslovi/1?q=${query}&l=${location}`);
      }
    },
    [history]
  );

  const searchClearedHandler = () => {
    fetchJobs(0, PAGE_SIZE);
    setShowPagination(true);
    history.push("/svi-poslovi/1");
  };

  const handlePageChange = (event: any, page: any) => {
    history.push("/svi-poslovi/" + page);
  };

  const fetchTraka = async () => {
    let response = await api.pravnaLica.fetchSponsoredCompanies(1);
    let data = await response.json();
    setLogos(data);
  };

  useEffect(() => {
    if (!page) {
      history.replace("/svi-poslovi/1");
    }

    if (hasQueryParams) {
      return;
    }

    fetchJobs(+page - 1, PAGE_SIZE);
  }, [page, history, hasQueryParams, fetchJobs]);

  useEffect(() => {
    fetchTraka();
  }, []);

  useEffect(() => {
    const { searchParam, locationParam } = queryParams;

    if (searchParam || locationParam) {
      searchHandler(searchParam ?? "", locationParam ?? "", false);
    }
  }, [queryParams]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Svi poslovi | Agencija Spektar</title>
        <meta name="description" content="Agencija Spektar" />
        <meta property="og:title" content="Svi poslovi | Agencija Spektar" />
        <meta property="og:image" content={previewImage} />
        <meta property="og:site_name" content="Agencija Spektar" />
      </Helmet>

      <PremiumJobs />

      <div className={styles.searchSection}>
        <h1 className={styles.searchText}>Pretražite sve poslove</h1>

        <Search
          onSearch={searchHandler}
          onClear={searchClearedHandler}
          className={styles.search}
        />
      </div>

      <div className={styles.content} ref={ref}>
        <JobsGrid loading={loading} konkursi={konkursi} />

        {showPagination && (
          <Pagination
            count={pagination?.totalPages}
            color="primary"
            shape="rounded"
            variant="outlined"
            style={{ paddingBottom: "10vh" }}
            page={+page}
            onChange={handlePageChange}
          />
        )}
      </div>

      <LogoSlider logos={logos} />
      <div className={styles.note}>
        <h1>Napomena</h1>
        <p>
          Oglasi koje objavljujemo na web stranici
          <a href="/" target="_blank">
            {" "}
            www.agencijaspektar.com{" "}
          </a>
          su podijeljeni u dvije grupe: oglase Agencije i one koje preuzimamo iz
          sredstava javnog informisanja.
        </p>
        <p>
          Oglasi objavljeni pod logom Agencije Spektar, kao i komercijalni
          oglasi objavljeni pod logom naših Klijenata su autorsko pravo Agencije
          Spektar. Zabranjeno je kopiranje teksta oglasa.
        </p>
        <p>
          Objavljivanjem oglasa podliježete pravnim posljedicama, odnosno
          naknadi nastale štete.
        </p>
        <p>
          Oglasi objavljeni iz sredstava javnog informisanja su preneseni u
          obliku u kojem su objavljeni u novinama, te nismo u mogućnosti da
          garantujemo tačnost istih ukoliko u originalnoj formi (u medijijma u
          kojima je objavljen) nije tačno prenesen, što nema povezanosti sa
          radom zaposlenih Agencije. Savjetujemo svim kandidatima da provjere
          iste kod poslodavca koji oglašava potrebu za radnicima jer se uslovi
          konkursa mogu promijeniti u međuvremenu.
        </p>
        <Divider className={styles.dividerRotated} />
      </div>

      <div className={styles.imgReveal}>
        <Footer />
      </div>
    </div>
  );
};
