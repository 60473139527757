// Libs
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {
  Paper,
  Table,
  Button as MuiButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from "@mui/material";

// Components
import { ItemDialog } from "./ItemDialog";

// Contexts
import { useAuth } from "../../../contexts/auth-context";

// Utils
import api from "../../../api";

import styles from "./SponsoredView.module.scss";

export interface LogoItem {
  id: number;
  slika: string;
  url: string;
  tip: number;
}

export const SponsoredView: React.FunctionComponent = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const [showDialog, setShowDialog] = useState(false);

  const [logoToEdit, setLogoToEdit] = useState<LogoItem | null>(null);
  const [logos, setLogos] = useState<LogoItem[]>([]);

  const history = useHistory();

  const fetchData = async () => {
    let logos = [];

    let response = await api.pravnaLica.fetchSponsoredCompanies(1);
    logos = await response.json();

    response = await api.pravnaLica.fetchSponsoredCompanies(2);
    const data = await response.json();

    logos = [...logos, ...data];

    setLogos(logos);
  };

  const onNewItemClick = () => {
    setLogoToEdit(null);
    setShowDialog(true);
  };

  const onDialogClose = () => {
    setLogoToEdit(null);
    setShowDialog(false);
    fetchData();
  };

  const onDeleteClick = async (id: number) => {
    await api.pravnaLica.deleteSponsoredCompany(id);
    fetchData();
  };

  const onEditClick = (logo: LogoItem) => {
    setLogoToEdit(logo);
    setShowDialog(true);
  };

  useEffect(() => {
    if (!isLoggedIn) history.push("/prijava");
    else fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <MuiButton
        variant="contained"
        style={{ width: "200px", marginLeft: "auto" }}
        onClick={onNewItemClick}
      >
        Novi unos
      </MuiButton>

      <ItemDialog
        open={showDialog}
        logo={logoToEdit}
        handleClose={onDialogClose}
      />

      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, overflow: "unset" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Slika</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Traka</TableCell>
              <TableCell>Akcije</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logos.map((logo) => (
              <TableRow
                key={logo.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{logo.id}</TableCell>
                <TableCell align="left">
                  <Link href={logo.slika} target="_blank">
                    {logo.slika}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link href={logo.url} target="_blank">
                    {logo.url}
                  </Link>
                </TableCell>
                <TableCell>{logo.tip}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => onEditClick(logo)}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => onDeleteClick(logo.id)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
