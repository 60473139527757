// Libs
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Components
import { Divider } from "../../components/Divider/Divider";
import { LoadingFallback } from "../../components/LoadingFallback/LoadingFallback";
import { LogoSlider } from "../../components/LogoSlider/LogoSlider";
import { Footer } from "../../layouts/Footer/Footer";
import { Space } from "../../containers/Space/Space";
import { TrainingsForm } from "../../components/TrainingsForm/TrainingsForm";
import { SocialsShare } from "../../components/SocialShare/SocialsShare";

// Utils
import { Blog } from "../../models/Blog";
import { getText } from "../../util";
import api from "../../api";
import styles from "./PostPage.module.scss";
import { Logo } from "../../api/services/pravna-lica";

interface PathParams {
  name: string;
  id: string;
}

export const PostPage: React.FunctionComponent = () => {
  const { id } = useParams<PathParams>();
  const [blog, setBlog] = useState<Blog | undefined>();
  const [loading, setLoading] = useState(false);
  const [logos, setLogos] = useState<Logo[]>([]);

  const pathname = window.location.href;

  const fetchBlog = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.blogs.fetchBlog(id!);
      const data = await response.json();
      setBlog(data);
      setLoading(false);
    } catch (err) {}
  }, [id]);

  const createStructuredJson = () => {
    if (blog) {
      let json = {
        "@context": "https://schema.org/",
        "@type": "BlogPosting",
        dateCreated: blog.vrijemeKreiranja,
        datePublished: blog.vrijemeKreiranja,
        headline: blog.naslov,
        name: blog.naslov,
        thumbnailUrl: window.location.href,
        image: blog.slika,
        url: window.location.href,
      };

      return JSON.stringify(json);
    }
  };

  const fetchTraka = async () => {
    let response = await api.pravnaLica.fetchSponsoredCompanies(1);
    let data = await response.json();
    setLogos(data);
  };

  useEffect(() => {
    fetchBlog();
  }, [fetchBlog]);

  useEffect(() => {
    fetchTraka();
  }, []);

  return (
    <div className={styles.container}>
      {blog && (
        <Helmet>
          <title>{blog.naslov || "Agencija Spektar"} </title>
          <meta name="description" content="Agencija Spektar" />
          <meta property="og:title" content={blog.naslov} />
          <meta
            name="image"
            property="og:image"
            content={blog.slika + "?w=800"}
          />
          <meta
            property="og:description"
            content={getText(blog.tekst).substring(0, 50) + "..."}
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="Agencija Spektar" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={blog.naslov} />
          <meta
            name="twitter:description"
            content={getText(blog.tekst).substring(0, 50) + "..."}
          />
          <meta name="twitter:image" content={blog.slika + "?w=800"} />

          <script type="application/ld+json">{createStructuredJson()}</script>
        </Helmet>
      )}

      <div className={styles.heading}>
        <h1>{blog?.naslov}</h1>
      </div>
      {blog && !blog?.aktivan && (
        <p className={styles.expired}>Ova prilika više nije aktivna.</p>
      )}

      <img className={styles.image} src={blog?.slika} alt={blog?.naslov} />

      {loading && <LoadingFallback />}

      <div
        className={styles.content}
        dangerouslySetInnerHTML={{
          __html: blog?.tekst || "",
        }}
      />

      <SocialsShare
        text="Podijelite ovaj članak:"
        url={pathname}
        style={{ marginRight: "5vw" }}
      />

      {blog?.kategorija.idGlavneKategorije === 2 && (
        <Space>
          <TrainingsForm id={blog.id.toString()} />
        </Space>
      )}

      <LogoSlider logos={logos} />
      <div className={styles.imgReveal}>
        <Divider className={styles.dividerRotated} />
        <Footer />
      </div>
    </div>
  );
};
