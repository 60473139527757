export const PAGE_SIZE = 12;

export const FACEBOOK_SHARE = "https://www.facebook.com/sharer.php?u=";
export const LINKEDIN_SHARE =
  "https://www.linkedin.com/shareArticle?mini=true&url=";
export const TWITTER_SHARE = "https://twitter.com/intent/tweet?url=";
export const VIBER_SHARE = "viber://forward?text=";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const MAX_FILE_SIZE = 26214400;

export const previewImage =
  "https://test.agencijaspektar.com:9000/slike/spektar-default.png?w=800";
