import { AlertColor, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

interface ToastMessageProps {
  message?: string;
  severity: AlertColor | undefined;
  open: boolean;
  onClose: () => void;
}

export const ToastMessage: React.FunctionComponent<ToastMessageProps> = ({
  message,
  open,
  severity,
  onClose,
}) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={10000}
      message={message}
      onClose={onClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="success"
        sx={{
          height: "70px",
          display: "flex",
          alignItems: "center",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
          background: "var(--green)",
          color: "white ",
        }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
