import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from "react";

interface ISelectionsContext {
  postId: number | undefined;
  jobId: number | undefined;
  setPostId: (id: number) => void;
  setJobId: (id: number) => void;
}

export const SelectionContext = createContext<ISelectionsContext | null>(null);

export const SelectionContextProvider: FunctionComponent<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const [postId, setPostId] = useState<number | undefined>();
  const [jobId, setJobId] = useState<number | undefined>();

  return (
    <SelectionContext.Provider value={{ postId, setPostId, jobId, setJobId }}>
      {children}
    </SelectionContext.Provider>
  );
};

export function useSelection() {
  const context = useContext(SelectionContext);

  if (!context) {
    throw new Error(
      "SelectionContext should be used within SelectionContextProvider"
    );
  }

  const setPostId = (id: number) => {
    context.setPostId(id);
  };

  const setJobId = (id: number) => {
    context.setJobId(id);
  };

  return { ...context, setPostId, setJobId };
}
