import styles from "./PremiumTag.module.scss";
import { ReactComponent as PremiumIcon } from "../../../assets/icons/premium.svg";

export const PremiumTag: React.FunctionComponent = () => {
  return (
    <div className={styles.premiumTag}>
      <PremiumIcon />
      <p className={styles.premiumTag__text}>premium</p>
    </div>
  );
};
