import Slider from "react-slick";

import { PremiumJobCard } from "../../components/PremiumJobCard";
import styles from "./PremiumJobs.module.scss";
import { Konkurs } from "../../models/Konkurs";
import { PremiumArticleCard } from "../../components/PremiumArticleCard";
import { Blog } from "../../models/Blog";
import { useCallback, useEffect, useMemo, useState } from "react";
import api from "../../api";

interface PremiumJobsProps {}

const SampleNextArrow: React.FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = ({ ...props }) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "3px",
        cursor: "pointer",
        zIndex: 12334,
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow: React.FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = ({ ...props }) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "3px",
        cursor: "pointer",
        zIndex: 12334,
      }}
      onClick={onClick}
    />
  );
};

let settings = {
  arrows: true,
  dots: true,
  speed: 1000,
  autoplay: true,
  accessibility: true,
  slidesToShow: 4,
  autoPlay: true,
  autoplaySpeed: 5000,
  slidesToScroll: 1,
  centerPadding: "10px",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1224,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 927,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 630,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

export const PremiumJobs: React.FunctionComponent<PremiumJobsProps> = () => {
  const [premiumKonkursi, setPremiumKonkursi] = useState<Konkurs[]>([]);
  const [premiumOglasi, setPremiumOglasi] = useState<Blog[]>([]);

  const hasItems = useMemo(
    () => premiumKonkursi.length > 0 || premiumOglasi.length > 0,
    [premiumKonkursi, premiumOglasi]
  );

  const fetchPremiumKonkursi = useCallback(async () => {
    try {
      const response = await api.konkursi.fetchPremium();

      if (response.status === 200) {
        const data = await response.json();
        setPremiumKonkursi(data);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchPremiumOglasi = useCallback(async () => {
    try {
      const response = await api.blogs.fetchPremiumBlogs();

      if (response.status === 200) {
        const data = await response.json();
        setPremiumOglasi(data);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchPremiumKonkursi();
    fetchPremiumOglasi();
  }, [fetchPremiumKonkursi, fetchPremiumOglasi]);

  if (!hasItems) {
    return <></>;
  }

  const renderPremiumOglasiCards = () => {
    return premiumOglasi.map((blog) => (
      <PremiumArticleCard key={`premium-oglas-${blog.id}`} blog={blog} />
    ));
  };

  const renderKonkursiCards = () => {
    return premiumKonkursi.map((konkurs) => (
      <PremiumJobCard key={`premium-konkurs-${konkurs.id}`} konkurs={konkurs} />
    ));
  };

  return (
    <Slider {...settings} className={styles.premiumJobs}>
      {renderKonkursiCards()}
      {renderPremiumOglasiCards()}
    </Slider>
  );
};
