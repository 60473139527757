import { createContext, useContext, useEffect, useState } from "react";

interface IAuthContext {
  isLoggedIn: boolean;
  setIsLoggedIn: (is: boolean) => void;
}

export const AuthContext = createContext<IAuthContext | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FunctionComponent<
  AuthProviderProps
> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + "login", {
      credentials: "include",
    }).then((response) => {
      if (response.status === 200) setIsLoggedIn(true);
      else setIsLoggedIn(false);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      "AuthContext should be used within the AuthContextProvider"
    );
  }

  return context;
};
