// Libs
import { SVGProps } from "react";
import { NavLink } from "react-router-dom";

// Assets
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-thick.svg";

import styles from "./DropdownMenuItem.module.scss";

export interface DropdownMenuItemProps {
  text: string;
  color?: string;
  Icon?: React.FC<SVGProps<SVGSVGElement>>;
  route: string;
}

export const DropdownMenuItem: React.FunctionComponent<
  DropdownMenuItemProps
> = ({ text, color, Icon, route = "/" }: DropdownMenuItemProps) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.iconDiv}
        style={{
          background:
            "linear-gradient(to bottom," + color + "70, " + color + "20)",
          borderRadius: "10px",
        }}
      >
        {Icon && <Icon className={styles.icon} />}
      </div>
      <div className={styles.text}>
        <NavLink to={route} className={styles.link}>
          <p>{text}</p>
        </NavLink>
      </div>
      <ArrowIcon className={styles.arrow} style={{ color: color }} />
    </div>
  );
};
