// Libs
import React from "react";
import classNames from "classnames";

// Assets
import location from "../../assets/icons/location.png";
import search from "../../assets/icons/search.png";

import styles from "./Input.module.scss";

const icons: Record<string, string> = {
  location: location,
  search: search,
};

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  children?: React.ReactNode;
  width?: string;
  type: "search" | "location";
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ id, width, className = "", type, ...rest }, forwardedRef) => {
    return (
      <div className={classNames(styles.container, className)}>
        <input
          ref={forwardedRef}
          id={id}
          autoComplete="off"
          className={classNames(styles.input, className)}
          style={{
            width: width,
            background: `url("${icons[type]}") no-repeat 20px`,
            backgroundSize: "20px",
          }}
          {...rest}
        />
      </div>
    );
  }
);

export default Input;
