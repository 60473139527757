interface Question {
  id: number;
  q: string;
  a: string;
}

export const faq: Question[] = [
  {
    id: 1,
    q: "Šta podrazumijeva priprema za intervju?",
    a: "Priprema za intervju podrazumijeva ponovno iščitavanje prosljeđene prijave, odnosno proslijeđene biografije. Ponovno čitate oglas (ukoliko se javljate na oglas), svaki detalj je važan, pogotovo koji se odnose na očekivana znanja, vještine i kompetencije, kao i uslove koje morate da ispunjavate. Raspitivanje o preduzeću i radnom mjestu daje vam priliku da postavite kvalitetna pitanja koja će vas učiniti konkurentnijim. Pripremite odgovore na standardna (očekivana) pitanja.",
  },
  {
    id: 2,
    q: "Kako da se ponašam na početku intervjua?",
    a: "Dođite uredni i adekvatno (primjereno konkursu koji se prijavljujete) obučeni. Srdačno pozdravite sagovornika, nasmiješite se, recite vaše ime i prezime. Kontrolišite znakove nervoze (dosta toga se može postići vježbanjem). Uskladite brzinu govora sa brzinom govora intervjuera. Ako intervjuer(i) počne da ćaska(ju), prihvatite ćaskanje ali kratko, ne preuzimajte inicijativu.",
  },
  {
    id: 3,
    q: "Koje odgovore treba da pripremim za intervju?",
    a: "Recite mi (nama) nešto o sebi? Zašto ste se odlučili za ovo preduzeće (organizaciju)? Koje su vaše najveće prednosti, kvalitete? Koja je vaša najveća slabost? U kakvoj vezi je(su) vaše(a) dosadašnje(a) (ne)iskustvo(a) sa ovim poslom? Koliku platu očekujete na ovom poslu? Koji su vaši dugoročni ciljevi, planovi? Zašto bismo trebali baš vas zaposliti? Da li imate nešto što smatrate bitnim da znamo?",
  },
  {
    id: 4,
    q: "Koje biste preporuke, generalno, dali građanima koji pokušavaju da nađu posao u inostranstvu?",
    a: "Za one koji su odlučili da posao traže u inostranstvu, pretražujte ponude i budite obazrivi, svašta se nudi. Ukoliko se ukaže prilika za odlazak, pokušajte ovdje pribaviti potrebne dokumente (dozvolu za rad, dozvolu za boravak, ugovor o radu, potvrdu smještaja vlasnika) kako biste od početka imali nekakvu ravnopravnost na tržištu rada gdje odlazite. Informišite se o postupku nostrifikacije diploma, mogućnosti spajanja porodica, saznajte što više o poslodavcu kod kojeg namjeravate započeti posao,… .",
  },
  {
    id: 5,
    q: "Kolike su današnje plate kod nas, a kolike dobiju naši radnici kada odu u inostranstvo?",
    a: "Najčešća formula za obračunavanje tržišnih plata izgleda na način, da se osnovici koja se trenutno plaća za radnika određenog zanimanja i kompetencija, pridoda ostvareni učinak u datom okruženju. Naša praksa pokazuje da postoje ozbiljne razlike u visini isplaćenih plata odnosno zarada za isti rad, od organizacije do organizacije. Tu praksu imamo kako kod nas u našem okruženju, tako i u inostranstvu. Ono šta je sve više trend, smanjuje se razlika u isplaćenim platama između BiH i inostranstva, pogotovo u pojedinim sektorima (IT, metalska branša, Call centri).",
  },
  {
    id: 6,
    q: "Iz kojih zemalja u “Agenciju Spektar” stižu zahtjevi za radnicima i koja zanimanja najčešće traže?",
    a: "Najčešći zahtjevi za radnicima stižu iz BiH. Pored tih zahtjeva, imamo upite iz drugih država, prije svega iz Hrvatske, Slovenije, Njemačke, Belgije, Austrije, ali nerjetko i iz drugih zemalja. Zanimanja za koje se poslodavci najviše interesuju zavise od trenutnih tržišnih potreba. Pored tradicionalno najtraženijih zanimanja kao što su sezonski, odnosno proizvodni radnici, radnici u ugostiteljstvu, radnici za rad u građevinarstvu, velika je potražnja za inženjerima, menadžerima, KV radnicima svih profila, informatičarima,.. . ",
  },
  {
    id: 7,
    q: "Koji elementi utiču na brzinu zaposlenja, odnosno, ko na tržištu rada ima najveće šanse i zbog čega?",
    a: "Najveće šanse za zapošljavanje imaju lica koja posjeduju tražena znanja i vještine potrebne tržištu. U današnje vrijeme, osobe sa više kompetencija, fokusirani na tražanje posla, sa prepoznatljivom motivacijom za rad, mogu brzo doći do posla. Poslodavci upravo očekuju takve kandidate da vide prilikom razgovora za posao.",
  },
  {
    id: 8,
    q: "Za koliko vremena, u prosjeku, nezaposleni preko “Spektra” pronađu posao?",
    a: "Mi smo zadnjih godina promjenili principe rada, prilagodili smo ih savremenom poslovnom okruženju.Kada objavimo upražnjeno radno mjesto, kandidati se javljaju u skladu sa njihovom procjenom da mogu da ispune navedene uslove konkursa. Šaljemo informaciju svim kandidatima koji su to od nas zatražili putem e-maila ili Viber zajednice da postoji otvoren konkurs, odnosno putem drugih kanala društvenih mreža, pa čekamo da se kandidati sami prijave. Oni kojima je posao stvarno potreban, oni se prijavljuju, ne odustaju prilikom prvog neuspješnog razgovora za posao, te se u svakom slučaju brže zaposle. Oni koji su po prirodi pasivni, koji čekaju da neko za njih pronađe posao, to zna da potraje dugo.",
  },
  {
    id: 9,
    q: "Kada i pod kojim uslovima ćemo imati šansu za stalni posao u ovim vremenima?",
    a: "Ako želimo biti zaposleni, bez obzira na uslove i okruženje, moramo se prilagođavati tržišnim trendovima. Trend su konstantne promjene koje uzrokuju povećanje ili smanjivanje potrebe za određenim znanjima ili profilima radnika. Ako imate sreću, nalazite se u branši i vrsti posla koja je tražena, samo od vas zavisi kakve uslove ćete ispregovarati. Ali ako ste u branši ili zanimanju koje nije traženo na tržištu, nema perspektivu, a želite da radite, pokušajte što prije da se prilagodite drugoj vrsti posla. Prilikom odabira nekog drugog poziva, vrste posla, birajte ono za šta mislite da će vam pričinjavati zadovoljstvo. Za one koji misle da su u naizgled bezizlaznoj situaciji, važno je da prihvate da im je za uspjeh potrebna motivacija, fokusiranost na traženje posla i spremnost na učenje. Tako će iz lošeg stanja, brzo doći u poziciju da biraju svoj posao i samostalno upravljaju vlastitom karijerom.",
  },
  {
    id: 10,
    q: "Da li je u odnosu na ranije godine, generalno, u padu ili porastu broj oglašenih radnih mjesta i onih koji traže posao i zbog čega?",
    a: "Raste samo broj oglašenih radnih mjesta. Razlog je nastala potreba za popunjavanjem upražnjenih radnih mjesta nastalih zbog odlaska radnika, ali i iz organizacionih promjena koje su uzrokovale pojavu novih radnih mjesta, drugačijih zahtjeva za radnicima nego što su bili nekada ili do sada. Kada analiziramo broj onih koji traže posao, imamo smanjenje nastavljeno zbog trenda odlaska ljudi sa ovih prostora.",
  },
  {
    id: 11,
    q: "Da li je broj zahtjeva stranih firmi za našim radnicima u padu ili porastu u odnosu na ranije godine i šta je razlog?",
    a: "Imamo ozbiljan trend rasta zahtjeva za radnicima sa ovih prostora. Razlog za to, ogleda se u kulturološkoj prilagodljivosti naših ljudi datim tržištima rada, kao i generalno prihvatljivim, boljim uslovima koji se nude u odnosu na one koje trenutno imaju radnici kod nas.",
  },
  {
    id: 12,
    q: "Da li postoje neke djelatnosti, odnosno poslovi, gdje iskustvo nije presudno?",
    a: "Naravno, za mnoga potencijalna radna mjesta to možemo reći. I to je super za one koji su stvarno motivisani za traženje posla. Većina današnjih poslova se može naučiti za kratko vrijeme, nisu potrebna posebna predznanja i poslovne vještine. To su poslovi koji pripadaju operativnom nivou. U dobro organizovanim organizacijama, postupak uvođenja novih saradnika je organizovan, pa lica bez radnog iskustva u tim okolnostima, brzo stiču kvalifikaciju za obavljanje posla. Organizacije koje do sada nisu uredile ovaj postupak, njima će to biti jedan od prioritetnih zadataka u narednom periodu.",
  },
  {
    id: 13,
    q: "U kojoj mjeri poslodavci u uslovima navode neophodno iskustvo i koliko godina traže u prosjeku?",
    a: "To je veoma važan podatak koji se treba istaći u oglasu. Ako posmatramo oglase gdje Agencija Spektar vrši predselekciju kandidata (to su oglasi pod logom Spektar), mi sa organizacijom koja prima nove saradnike precizno utvrđujemo zahtjevano radno iskustvo za upražnjeno radno mjesto. Zbog situacije na tržištu rada, odnosno potencijala i strukture radne snage, pokušavamo se izboriti za što veću mogućnost aplikacija, samim tim i što manje zahtjevano radno iskustvo. Tako da ono šta stoji u našem oglasu, sigurno je uslov za zapošljavanje. Kada se radi o drugim istaknutim oglasima koji su pod logom organizacije koja zapošljava i traži radnike, mi ne utičemo na isticanje tog podatka, tako da ga ne možemo komentarisati.",
  },
  {
    id: 14,
    q: "Koliko ljudi bez radnog iskustva imaju šanse na našem tržištu rada?",
    a: "Šanse za zapošljavanje ljudi bez radnog iskustva danas su manje nego prije par godina. Razloga je puno, najviše u nedostatku vremena, novca, kao i povjerenja u cijeli proces od strane poslodavaca. Proces osposobljavanja lica bez radnog iskustva do očekivanog nivoa potencijalne produktivnosti, za organizaciju je skup, neizvjestan, a na kraju se pokaže često i uzaludan, jer mnoga lica bez radnog iskustva, prilikom prvog zapošljavanja, kada dostignu određeni nivo produktivnosti, kompetencija, poslovnih vještina, napuštaju svojevoljno organizaciju (što je prirodno iz ugla radnika), pa se iz tih razloga organizacije, odnosno poslodavci prilikom izbora novih saradnika, češće odlučuju za već formirane kandidate",
  },
  {
    id: 15,
    q: "Da li je evidentan veći broj oglas za sezonski rad, o kojim poslovima je najčešće riječ?",
    a: "Pouzdanih podataka o obimu sezonskih poslova u BiH nemamo. Pretpostavljamo da je najveći broj sezonaca na našim prostorima zaposleno u ugostiteljstvu, građevinarstvu, poljoprivredi i voćarstvu, a kada govorimo o sezonskom zapošljavanju u inostranstvu, najviše je zaposleno pomoćnih radnika, zanatskih radnika svih profila, kao i radnika u ugostiteljstvu. ",
  },
  {
    id: 16,
    q: "Zašto se Vaši kandidati već nisu javili na oglas koji smo objavili?",
    a: "Postoji više razloga. Prije svega novine su pune loših oglasa, a loš oglas dosta govori o samoj organizaciji. Veliki broj kandidata ne prati oglase, njihovo vrijeme je skupo. Zato angažuju nas i mi ih zastupamo. Dosta firmi ima loše pripremljenu selekciju kandidata. Šta reći o firmama gdje više kandidata u isto vrijeme radi testove ili za one u kojima se intervju obavlja pred drugim kandidatima? Tu su i strahovi da će izgubiti postojeći posao kada onaj ko se oglašava pokuša saznati nešto više u kandidatu organizaciji u kojoj radi.",
  },
  {
    id: 17,
    q: "Poslodavac sam i tražim radnike. Šta je posredovanje pri zapošljavanju?",
    a: "Posredovanje pri zapošljavanju je proces pronalaženja poslodavcima potrebnih kadrova, prema kome poslodavac kaže kakav treba da bude budući saradnik, a agencija mu na tržištu pronađe jednog ili još bolje nekoliko kandidata kako bi poslodavac mogao da selektuje.",
  },
  {
    id: 18,
    q: "Koji su uslovi za prijavu?",
    a: "U našu bazu prijaviti se može apsolutno svako ko je zainteresovan da aktivno učestvuje u postupku traženja posla, bez obzira na pol, stručnu spremu ili radno iskustvo. Potrebno je da dođete u naše prostorije ili zakažete razgovor popunom formulara na agencijskom portalu. Ukoliko niste u mogućnosti da dođete u naše prostorije, obavićemo online razgovor. ",
  },
  {
    id: 19,
    q: "Hoću li biti obaviješten/a ako se popuni data pozicija?",
    a: "Svi naši kandidati ( za oglase objavljene pod logoom Agencije Spektar) dobiju povratnu informaciju na e-mail, viber ili kontakt telefon u vezi sa statusom nakon intervjua, ili prije intervjua, ukoliko ne ispunjavaju sve navedene uslove.",
  },
];
