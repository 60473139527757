// Components
import { useEffect, useState } from "react";

// Services
import api from "../../api";
import { Logo } from "../../api/services/pravna-lica";

// Components
import { Divider } from "../../components/Divider/Divider";
import { LogoSlider } from "../../components/LogoSlider/LogoSlider";

// Layouts
import { Footer } from "../../layouts/Footer/Footer";

import styles from "./View.module.scss";

type NativeProps = React.HtmlHTMLAttributes<HTMLDivElement>;

interface ViewProps extends NativeProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
}

export const Root: React.FunctionComponent<ViewProps> = ({
  children,
  title,
  description,
}) => {
  const [logos, setLogos] = useState<Logo[]>([]);

  const fetchTraka = async () => {
    let response = await api.pravnaLica.fetchSponsoredCompanies(1);
    let data = await response.json();
    setLogos(data);
  };

  useEffect(() => {
    fetchTraka();
  }, []);

  return (
    <div className={styles.container}>
      {title && (
        <div className={styles.text}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.description}>{description}</p>
        </div>
      )}

      {children}
      <div className={styles.section}>
        <LogoSlider logos={logos} />
        <Divider className={styles.dividerRotated} />
      </div>

      <div className={styles.imgReveal}>
        <Footer />
      </div>
    </div>
  );
};

interface ContentProps extends NativeProps {
  children: React.ReactNode;
}

export const Content: React.FunctionComponent<ContentProps> = ({
  children,
}) => {
  return <div className={styles.cards}>{children}</div>;
};

export const View = Object.assign(Root, {
  Content,
});
