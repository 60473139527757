// Libs
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Components
import { Pagination } from "@mui/material";
import { BlogPost } from "../BlogPage/BlogPost/BlogPost";
import { LoadingFallback } from "../../components/LoadingFallback/LoadingFallback";
import { View } from "../../containers/view/View";

// Utils
import { Blog } from "../../models/Blog";
import { PageableBlogResponse } from "../../models/PageableBlogResponse";
import { PAGE_SIZE, previewImage } from "../../constants";

import api from "../../api";
import styles from "./BlogArchive.module.scss";

interface PageParams {
  page: string;
}

export const BlogArchive: React.FunctionComponent = () => {
  const { page } = useParams<PageParams>();
  const history = useHistory();

  const [posts, setPosts] = useState<Blog[]>([]);

  const [pagination, setPagination] = useState<PageableBlogResponse>();
  const [loading, setLoading] = useState(false);

  const fetchPosts = useCallback(async (page: number, size: number) => {
    setLoading(true);
    try {
      const response = await api.blogs.fetchMedia(page, size);

      if (response.status === 200) {
        const data = await response.json();
        setPagination(data);
        setPosts(data.content);
        setLoading(false);
      }
    } catch (err) {}
  }, []);

  const handlePageChange = (event: any, page: any) => {
    history.push("/mediji/" + page);
  };

  useEffect(() => {
    fetchPosts(+page - 1, PAGE_SIZE);
  }, [fetchPosts, page]);

  return (
    <View
      title="Mediji"
      description="Pronađite interesantne tekstove vezane za tržište rada, kako aktuelne, tako i one koje smo odabrali iz prethodnog perioda."
    >
      <Helmet>
        <title>Mediji | Agencija Spektar</title>
        <meta name="description" content="Agencija Spektar" />
        <meta property="og:title" content="Mediji | Agencija Spektar" />
        <meta property="og:image" content={previewImage} />
        <meta property="og:site_name" content="Agencija Spektar" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>

      <View.Content>
        <div className={styles.content}>
          {loading ? (
            <LoadingFallback />
          ) : posts.length > 0 ? (
            posts.map((post) => <BlogPost blog={post} key={post.id} />)
          ) : (
            <p style={{ margin: "auto" }}>Nisu pronađeni članci.</p>
          )}
        </div>

        <Pagination
          page={+page}
          count={pagination?.totalPages}
          color="primary"
          shape="rounded"
          variant="outlined"
          style={{ paddingBottom: "10vh", margin: "auto" }}
          onChange={handlePageChange}
        />
      </View.Content>
    </View>
  );
};
