// Libs
import { useCallback, useEffect, useState } from "react";

// Components
import { Konkurs } from "../../models/Konkurs";
import { JobCard } from "../JobCard/JobCard";

import styles from "./JobGroup.module.scss";

export interface JobGroupProps {
  konkursi: Konkurs[];
}

export const JobGroup = ({ konkursi }: JobGroupProps) => {
  const [current, setCurrent] = useState(0);
  const [delay] = useState(4000 + Math.floor(Math.random() * 2000) + 1);
  const [id, setId] = useState<NodeJS.Timer>();

  const next = useCallback(() => {
    setCurrent(() => {
      return current + 1 >= konkursi.length ? 0 : current + 1;
    });
  }, [current, konkursi.length]);

  const prev = () => {
    setCurrent(() => {
      return current - 1 < 0 ? konkursi.length - 1 : current - 1;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, delay);

    setId(interval);
    return () => clearInterval(interval);
  }, [delay, next]);

  return (
    <div className={styles.container}>
      <div className={styles.tag}>
        <p>Grupa oglasa</p>
      </div>
      <div className={styles.lightShadow} />
      <div className={styles.darkShadow} />

      <div className={styles.card}>
        <div
          className={styles.arrowLeft}
          onClick={() => {
            prev();
            clearInterval(id);
          }}
        ></div>
        <div className={styles.cardWrapper}>
          <JobCard
            konkurs={konkursi[current]}
            className={styles.konkurs}
            buttonStyle={styles.button}
          />
        </div>
        <div
          className={styles.arrowRight}
          onClick={() => {
            next();
            clearInterval(id);
          }}
        ></div>
      </div>
    </div>
  );
};
