// Libs
import urlSlug from "url-slug";

// Assets
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";

// Utils
import { Blog } from "../../../models/Blog";
import { getText } from "../../../util";

import styles from "./BlogPost.module.scss";

interface BlogPostProps {
  blog: Blog;
}

export const BlogPost: React.FunctionComponent<BlogPostProps> = ({ blog }) => {
  return (
    <a
      className={styles.container}
      href={"/objava/" + blog.id + "/" + urlSlug(blog.naslov)}
    >
      <div
        style={{ background: `url(${blog.slika})` }}
        className={styles.img}
      />
      <div className={styles.content}>
        <h1>{blog.naslov}</h1>
        <p>{getText(blog.tekst)}</p>
        <div className={styles.actions}>
          Opširnije <Arrow style={{ width: "30px" }} />
        </div>
      </div>
    </a>
  );
};
