import moment from "moment";
import "moment/locale/bs";

const scrollToTop = (top: number) => {
  const container = document.getElementById("container");
  container?.scrollTo({ top, left: 0, behavior: "smooth" });
};

const formatDate = (date: Date = new Date()) => {
  return moment(date).locale("bs").format("l");
};

export const getText = (html: string) => {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
};

export { scrollToTop, formatDate };
