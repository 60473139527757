// Libs
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { debounce } from "lodash";

// Components
import Input from "../../../components/Input/Input";
import { CompanyModal } from "./Modal/Modal";

import { useAuth } from "../../../contexts/auth-context";
import styles from "./CompaniesView.module.scss";
import api from "../../../api";

import placeholder from "../../../assets/images/placeholder.png";

interface PravnoLice {
  id: number;
  naziv: string;
  slika: string;
}

export const CompaniesView: React.FunctionComponent = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const [queryClear, setQueryClear] = useState(false);
  const [query, setQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pravnaLica, setPravnaLica] = useState<PravnoLice[]>([]);
  const [pravnoLice, setPravnoLice] = useState<PravnoLice | undefined>();

  const history = useHistory();

  const onSearch = async () => {
    const response = await api.pravnaLica.searchPravnaLica(query);

    if (response.status === 200) {
      const data = await response.json();
      setPravnaLica(data);
    } else if (response.status === 401) {
      setIsLoggedIn(false);
      history.push("/prijava");
    }
  };

  const clearQueryFiled = () => {
    setQuery("");
    setQueryClear(false);
    setPravnaLica([]);
  };

  const debouncedSearch = useCallback(
    debounce(async () => {
      onSearch();
    }, 1000),
    [query]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryClear(true);
    if (e.target.value === "") setQueryClear(false);

    setQuery(e.target.value);
    if (e.target.value.length > 3) debouncedSearch();
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isLoggedIn) history.push("/prijava");
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <Input
          onChange={onChange}
          value={query}
          className={styles.input}
          placeholder="Pretražite pravno lice"
          // icon="/assets/icons/search.png"
          type="search"
        />
        <div
          className={classNames(
            styles.clear,
            queryClear ? styles.show : styles.hide
          )}
          onClick={clearQueryFiled}
        ></div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {pravnaLica.map((pl) => {
            return (
              <div
                className={classNames(
                  styles.card,
                  pravnoLice?.id === pl.id ? styles.selected : ""
                )}
                onClick={() => {
                  setPravnoLice(pl);
                  setIsModalOpen(true);
                }}
              >
                <img src={pl.slika ?? placeholder} alt="" />
                <p>{pl.naziv}</p>
              </div>
            );
          })}
        </div>
      </div>

      {pravnoLice && (
        <CompanyModal
          open={isModalOpen}
          naziv={pravnoLice!.naziv}
          id={pravnoLice!.id}
          onClose={onModalClose}
        />
      )}
    </div>
  );
};
