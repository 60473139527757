import styles from "./AppLayout.module.scss";

import { Header } from "../Header/Header";
import { ViberGroup } from "../../components/ViberGroup/ViberGroup";

export interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  return (
    <div className={styles.layoutContainer}>
      <div className={styles.headerContainer}>
        <Header />
      </div>
      <div className={styles.wrapper}>
        <ViberGroup />
        <div className={styles.contentContainer}>{children}</div>
      </div>
    </div>
  );
};

export default AppLayout;
