// Libs
import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

// Components
import { Modal } from "@mui/material";
import { Button } from "../../../../components/Button/Button";
import Dropzone from "react-dropzone";

// Assets
import UploadFileIcon from "@mui/icons-material/UploadFile";

import styles from "./Modal.module.scss";

interface CompanyModalProps {
  naziv: string;
  id: number;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  slika: File;
  idPravnogLica: number;
};

const schema = yup.object().shape({
  slika: yup.mixed().required("Polje je obavezno"),
});

export const CompanyModal: React.FunctionComponent<CompanyModalProps> = ({
  naziv,
  open,
  id,
  onClose,
}) => {
  const [file, setFile] = useState<File | null>(null);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const handleClose = () => onClose();

  const onSubmit = async ({ slika }: FormValues) => {
    const formData = new FormData();

    formData.append("slika", slika);
    formData.append("idPravnogLica", id.toString());

    // const response = await api.pravnaLica.addImage(formData);

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + `admin/pravno-lice`,
      {
        method: "post",
        body: formData,
        credentials: "include",
      }
    );

    if (response.status === 200) {
      handleClose();
      setFile(null);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <p className={styles.title}>{naziv}</p>

        <Controller
          name="slika"
          control={control}
          render={(props) => (
            <Dropzone
              onDrop={(acceptedFiles) => {
                setValue("slika", acceptedFiles[0]);
                setFile(acceptedFiles[0]);
              }}
              {...props}
            >
              {({ getRootProps, getInputProps }) => (
                <section
                  className={styles.upload}
                  style={{
                    borderColor: errors.slika && !file ? "red" : "#00aadc",
                  }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} name="slika" />
                    {file ? (
                      <p>{file.name}</p>
                    ) : (
                      <p>Prevucite dokument ili kliknite da izaberete. </p>
                    )}
                  </div>
                  <UploadFileIcon style={{ color: "#00aadc" }} />
                </section>
              )}
            </Dropzone>
          )}
        />

        {/* <div className={styles.upload}>
          <p>Izaberite sliku pravnog lica.</p>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              type="file"
              onChange={(e) => {
                const file = e.currentTarget.files;
                setFile(file ? file[0] : null);
              }}
            />
            <UploadFileIcon />
            {file && (
              <p style={{ fontSize: "16px", marginLeft: "6px" }}>
                {file?.name}
              </p>
            )}
          </IconButton>
        </div> */}
        <Button type="submit" style={{ margin: "auto", width: "200px" }}>
          Dodaj sliku
        </Button>
      </form>
    </Modal>
  );
};
