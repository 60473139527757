import { get } from "../client";

const requestOptions = {
  headers: {
    authorization: "Basic " + process.env.REACT_APP_AUTH_KEY,
  },
};

export const fetchMainCategories = async () => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL + "clanci/glavne-kategorije",
    requestOptions
  );
  return response;
};

export const fetchSubcategories = async (id: number) => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL +
      `clanci/kategorije?idGlavneKategorije=${id}`,
    requestOptions
  );

  return response;
};
