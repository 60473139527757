// Libs
import api from "../../api";
import { Helmet } from "react-helmet";

// Components
import { BlogCard } from "../../components/BlogCard/BlogCard";
import { LoadingFallback } from "../../components/LoadingFallback/LoadingFallback";
import { View } from "../../containers/view/View";

// Utils
import { Blog } from "../../models/Blog";

import { useEffect, useState } from "react";
import styles from "./CareerAdvicePage.module.scss";
import { previewImage } from "../../constants";

export const CareerAdvicesPage: React.FunctionComponent = () => {
  const [posts, setPosts] = useState<Blog[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await api.blogs.fetchBlogs("9");
      const data = await response.json();
      if (response.status === 200) {
        setPosts(data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <View
      title="Karijerno savjetovanje"
      description="Iskoristite priliku i saznajte šta treba da znate ako tražite posao ili promjenu posla."
    >
      <Helmet>
        <title>Karijerno savjetovanje | Agencija Spektar</title>
        <meta name="description" content="Agencija Spektar" />

        <meta
          property="og:title"
          content="Karijerno savjetovanje | Agencija Spektar"
        />
        <meta property="og:image" content={previewImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Agencija Spektar" />
      </Helmet>

      <View.Content>
        <div className={styles.cards}>
          {loading && <LoadingFallback />}
          {!loading && posts.length === 0 ? (
            <p style={{ margin: "auto", marginTop: "20vh" }}>
              Nisu pronađene nove objave.
            </p>
          ) : (
            posts.map((e) => {
              return <BlogCard blog={e} key={e.id} />;
            })
          )}
        </div>
      </View.Content>
    </View>
  );
};
