// Libs
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

// Rest
import api from "../../../../api";
import { LogoItem } from "../SponsoredView";

type FormValues = {
  slika: string;
  url: string;
  tip: number;
};

interface ItemDialogProps extends DialogProps {
  open: boolean;
  logo: LogoItem | null;
  title?: string;
  handleClose: () => void;
}

export const ItemDialog: React.FunctionComponent<ItemDialogProps> = ({
  open,
  logo,
  title,
  handleClose,
  ...props
}) => {
  const { handleSubmit, reset, setValue, control } = useForm<FormValues>({});

  const onSubmit = async (values: FormValues) => {
    if (logo) {
      await api.pravnaLica.editSponsoredCompany(
        logo.id,
        JSON.stringify(values)
      );
    } else {
      await api.pravnaLica.addSponsoredCompany(JSON.stringify(values));
    }

    reset();
    handleClose();
  };

  const onClose = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (logo) {
      setValue("slika", logo.slika);
      setValue("url", logo.url);
      setValue("tip", logo.tip);
    } else {
      reset();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogTitle>Traka</DialogTitle>
      <DialogContent style={{ minWidth: "500px", paddingBottom: "30px" }}>
        <DialogContentText>
          {logo ? "Izmjena unosa" : "Novi unos."}
        </DialogContentText>

        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="slika"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Slika"
                margin="dense"
                fullWidth
                placeholder="Slika"
              />
            )}
          />

          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                fullWidth
                label="Link"
                placeholder="Link"
              />
            )}
          />

          <FormControl>
            <InputLabel id="demo-simple-select-label">Traka</InputLabel>

            <Controller
              name="tip"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth
                  margin="dense"
                  placeholder="Traka"
                  labelId="demo-simple-select-label"
                >
                  <MenuItem value={1}>1 </MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <Button variant="contained" type="submit" href="">
            Potvrdi
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
