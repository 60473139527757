import { useEffect } from "react";
import { TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/Button/Button";

import styles from "./LoginPage.module.scss";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../contexts/auth-context";

const schema = yup.object().shape({
  username: yup.string().required("Polje je obavezno"),
  password: yup.string().required("Polje je obavezno"),
});

type FormValues = {
  username: string;
  password: string;
};

export const LoginPage: React.FunctionComponent = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const history = useHistory();

  const onSubmit = async ({ username, password }: FormValues) => {
    const encoded = btoa(`${username}:${password}`);

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "login",
        {
          method: "get",
          headers: {
            authorization: "Basic " + encoded,
          },
          credentials: "include",
        }
      );

      if (response.status === 200) {
        setIsLoggedIn(true);
        history.push("/admin/blogovi");
      }
    } catch (err) {}
  };

  const onLogoClick = () => {
    history.push("/");
  };

  useEffect(() => {
    if (isLoggedIn) history.push("/admin/blogovi");
  }, [isLoggedIn]);

  return (
    <div className={styles.container}>
      <div className={styles.logo} onClick={onLogoClick}></div>
      <p>Prijavite se</p>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="outlined-required"
              label="Korisničko ime"
              placeholder="korisničko ime"
              margin="none"
              fullWidth
              error={!!errors.username}
              helperText={errors.username ? errors.username.message : ""}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={(field) => (
            <TextField
              {...field}
              id="password-required"
              label="Lozinka"
              type="password"
              fullWidth
              {...register("password")}
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
            />
          )}
        />

        <Button type="submit" className={styles.btn}>
          Prijavi se
        </Button>
      </form>
    </div>
  );
};
