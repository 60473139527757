import { Modal } from "@mui/material";
import React from "react";
import { Blog } from "../../../../models/Blog";
import { BlogForm } from "../BlogForm/BlogForm";
import styles from "./EditModal.module.scss";

interface EditModalProps {
  open: boolean;
  blog: Blog | null;
  handleClose: () => void;
}
export const EditModal: React.FunctionComponent<EditModalProps> = ({
  open,
  blog,
  handleClose,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div
        style={{
          width: "70%",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <BlogForm blog={blog} className={styles.form} />
      </div>
    </Modal>
  );
};
