import classNames from "classnames";

import styles from "./Button.module.scss";

export type ButtonType = "primary" | "default" | "link" | "submit";

const buttonTypeClasses: Record<ButtonType, string> = {
  primary: styles.primary,
  default: styles.default,
  link: styles.link,
  submit: styles.primary,
};

export interface ButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "type"
  > {
  children?: React.ReactNode;
  width?: string;
  height?: string;
  type: ButtonType;
}

export const Button = ({
  width,
  height,
  type,
  children,
  className = "",
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        type === "link" ? styles.link : styles.button,
        buttonTypeClasses[type],
        className
      )}
      style={{ width: width, height: height }}
      {...rest}
    >
      {children}
    </button>
  );
};
