// Libs
import Marquee from "react-fast-marquee";

// Hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";

// Services
import { Logo } from "../../api/services/pravna-lica";

import styles from "./LogoSlider.module.scss";

type DirectionType = "left" | "right";

export interface LogoSliderProps {
  logos: Logo[];
  direction?: DirectionType;
}

export const LogoSlider: React.FunctionComponent<LogoSliderProps> = ({
  logos,
  direction,
}) => {
  const { height, width } = useWindowDimensions();

  const onLogoClick = (logo: Logo) => {
    let link = logo.url.startsWith("https://")
      ? logo.url
      : "https://" + logo.url;

    window.open(link, "_blank");
  };

  return (
    <Marquee
      className={styles.slider}
      direction={direction}
      gradientWidth={20}
      speed={Math.ceil(width / 80)}
    >
      {logos.map((logo, index) => {
        return (
          <div
            className={styles.logo}
            style={{ backgroundImage: `url(${logo.slika})` }}
            key={`logo-${index}`}
            onClick={() => onLogoClick(logo)}
          />
        );
      })}
    </Marquee>
  );
};
