// Libs
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Components
import { FormGroup, TextField } from "@mui/material";
import { Button } from "../Button/Button";
import { ToastMessage } from "../ToastMessage/ToastMessage";

import api from "../../api";

import styles from "./TrainingsForm.module.scss";

type FormValues = {
  ime: string;
  prezime: string;
  email: string;
  komentar: string;
  brojTelefona: string;
};

interface TrainingsFormProps {
  id: string;
}

const schema = yup.object().shape({
  ime: yup.string().required("Polje je obavezno"),
  prezime: yup.string().required("Polje je obavezno"),
  email: yup.string().email().required("Polje je obavezno"),
  komentar: yup.string(),
  brojTelefona: yup.string().required("Polje je obavezno"),
});

export const TrainingsForm: React.FunctionComponent<TrainingsFormProps> = ({
  id,
}) => {
  const [isToastVisible, setIsToastVisible] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    defaultValues: {
      ime: "",
      prezime: "",
      email: "",
      komentar: "",
      brojTelefona: "",
    },
  });

  const onSubmit = async ({
    ime,
    prezime,
    email,
    komentar,
    brojTelefona,
  }: FormValues) => {
    const formData = new FormData();

    formData.append("ime", ime);
    formData.append("prezime", prezime);
    formData.append("email", email);
    formData.append("komentar", komentar);
    formData.append("brojTelefona", brojTelefona);

    try {
      await api.blogs.applyForTraining(id, formData);
      reset();
      setIsToastVisible(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.container}>
      <h1>Prijavite se za obuku</h1>

      {isToastVisible && (
        <ToastMessage
          open={isToastVisible}
          message="Vaša prijava je zabilježena!"
          severity="success"
          onClose={() => setIsToastVisible(false)}
        />
      )}

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className={styles.topInputs}>
          <Controller
            name="ime"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.input}
                id="outlined-required"
                label="Ime *"
                placeholder="Ime"
                margin="none"
                error={!!errors.ime}
                helperText={errors.ime ? errors.ime.message : ""}
              />
            )}
          />

          <Controller
            name="prezime"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.input}
                id="outlined-required"
                label="Prezime *"
                placeholder="Prezime"
                margin="none"
                error={!!errors.prezime}
                helperText={errors.prezime ? errors.prezime.message : ""}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.input}
                type="email"
                id="outlined-required"
                label="Email *"
                placeholder="primjer@mail.com"
                margin="none"
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            )}
          />

          <Controller
            name="brojTelefona"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="tel"
                className={styles.input}
                id="outlined-required"
                label="Broj telefona *"
                placeholder="+387 xx xxx xxx"
                error={!!errors.brojTelefona}
                helperText={
                  errors.brojTelefona ? errors.brojTelefona.message : ""
                }
              />
            )}
          />
          <Controller
            name="komentar"
            control={control}
            render={({ field }) => (
              <TextField
                label="Komentar"
                placeholder="Ovdje možete ostaviti komentar"
                multiline
                fullWidth
                {...field}
                rows={4}
                error={!!errors.komentar}
                helperText={errors.komentar ? errors.komentar.message : ""}
              />
            )}
          />
        </FormGroup>

        <Button type="submit" style={{ margin: "auto", width: "200px" }}>
          Prijavi se
        </Button>
      </form>
    </div>
  );
};
