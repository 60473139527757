import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
} from "@mui/material";

import api from "../../../api";

import { Button } from "../../../components/Button/Button";

import styles from "./NewsletterForm.module.scss";

type FormValues = {
  email: string;
  accept: boolean;
};

const schema = yup.object().shape({
  email: yup.string().email("Neispravan format").required("Polje je obavezno"),
  accept: yup
    .boolean()
    .oneOf([true], "Molimo prihvatite izjavu o saglasnosti."),
});

export const NewsletterForm: React.FunctionComponent = ({}) => {
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      accept: false,
    },
  });

  const onSubmit = async ({ email, accept }: FormValues) => {
    const formData = new FormData();

    formData.append("email", email);

    try {
      reset();
      await api.konkursi.newsletter(formData);
      setValue("accept", false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.input}
                type="email"
                id="outlined-required"
                label="Email *"
                placeholder="primjer@mail.com"
                margin="none"
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            )}
          />

          <FormControlLabel
            control={
              <Controller
                control={control}
                name="accept"
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox {...field} checked={getValues("accept")} />
                )}
              />
            }
            label="Slažem se sa politikom privatnosti"
          />
          {errors.accept ? (
            <FormHelperText error>
              Molimo prihvatite izjavu o saglasnosti.
            </FormHelperText>
          ) : (
            ""
          )}
        </FormGroup>
        <Button type="submit" style={{ margin: "auto", width: "200px" }}>
          Prijava
        </Button>
      </form>
    </div>
  );
};
