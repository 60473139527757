import { get, post, put, remove } from "../client";

export interface Logo {
  slika: string;
  url: string;
}

const requestOptions = {
  headers: {
    authorization: "Basic " + process.env.REACT_APP_AUTH_KEY,
    "Content-Type": "application/json",
  },
};

export const searchPravnaLica = async (query: string) => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL + `pravna-lica/search?query=${query}`,
    requestOptions
  );
  return response;
};

export const addImage = async (body: FormData) => {
  const response = await post(
    process.env.REACT_APP_BACKEND_URL + "admin/pravno-lice",
    { ...requestOptions, body }
  );
  return response;
};

// tip = 1 gornja traka
// tip = 2 donja traka
export const fetchSponsoredCompanies = async (tip: number) => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL + `traka/${tip}`,
    {
      ...requestOptions,
    }
  );
  return response;
};

export const addSponsoredCompany = async (body: string) => {
  const response = await post(
    process.env.REACT_APP_BACKEND_URL + "admin/traka",
    {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body,
    }
  );
  return response;
};

export const deleteSponsoredCompany = async (id: number) => {
  const response = await remove(
    process.env.REACT_APP_BACKEND_URL + `admin/traka/${id}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const editSponsoredCompany = async (id: number, body: string) => {
  const response = await put(
    process.env.REACT_APP_BACKEND_URL + `admin/traka/${id}`,
    {
      credentials: "include",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};
