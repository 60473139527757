import styles from "./Tag.module.scss";

export interface TagProps {
  text: string;
  color: string;
  type: string;
  action: (key: string) => void;
}

export const Tag = ({ text, color, action, type }: TagProps) => {
  return (
    <div
      onClick={() => action(type)}
      className={styles.container}
      style={{ backgroundColor: color + "20", border: `1px solid ${color}` }}
    >
      <p>{text}</p>
    </div>
  );
};
