import styles from "./CenterLayout.module.scss";

export interface CenterLayoutProps {
  children: React.ReactNode;
}

const CenterLayout = ({ children }: CenterLayoutProps) => {
  return <div className={styles.layoutContainer}>{children}</div>;
};

export default CenterLayout;
